/* Global */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, li, input, button, a, label, span {
    font-family: 'Poppins', sans-serif;
}

.panelMenu img
{
    filter: brightness(0) invert(1) !important;
}

.button {
    cursor: pointer;
    outline: none;
    border: none;
    text-transform: uppercase;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

ul {
    list-style-type: none;
}

.d-mobile {
    display: none !important;
}

/* Login page */
.loginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--black-primary);
}

.loginPage__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--black-secondary);
    border: 1px solid #c3c4c7;
    padding: 20px;
}

.label {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    font-weight: 400;
    font-size: 14px;
}

.input {
    margin: 4px 0;
    border: 1px solid #8c8f94;
    background: transparent;
}

.input--adminLogin {
    width: 300px;
    height: 50px;
    padding: 5px 10px;
}

.button--loginForm {
    background: var(--black-primary);
    color: #fff;
    font-weight: 700;
    width: 100%;
    height: 50px;
    transition: .4s all;
}

.button--loginForm:hover {
    letter-spacing: 2px;
}

.formError {
    font-size: 13px;
    color: #ee0000;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}

/* Panel */
.panel {
    background: var(--black-secondary);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

#menuItem--active {
    background: #4653B1;
}

.panelMenu__menu {
    display: block;
    background: var(--black-secondary) !important;
}

@media(max-width: 776px) {
    .panelMenu__menu {
        display: none;
    }
}

.panelMenu__top {
    margin: 50px 20px 70px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.panelMenu__top__logoWrapper {
    width: 20%;
    height: auto;
}

.panelMenu .panelMenu__top .panelMenu__top__logo {
    width: 100%;
    height: auto;
    filter: none !important;
}

.panelMenu__top__header {
    font-size: 14px;
    font-weight: 400;
}

.panelMenu__top__subheader {
    font-size: 16px;
}

.panelMenu {
    width: 20%;
    min-width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--main);
}

.panelMenu * {
    color: #fff;
}

.panelMenu__list {
    margin-left: 0;
    padding-left: 0;
}

.panelMenu__list__item__img {
    width: 20px;
    height: auto;
}

.panelMenu__list__item__link {
    width: 90%;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 20px auto;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #fff !important;
    transition: .2s all ease-in-out;
}

.panelMenu__list__item__link:hover,
.panelMenu__list__subitem:hover
{
    background: #4653B1;
}

.panelMenu__list__item__img {
    margin-right: 20px;
}

.panelMenu__list__subitem {
    color: #fff !important;
}

.panelMenu__list__subitem__img {
    width: 20px;
    margin-right: 20px;
    margin-left: 40px;
}

.panelMenu__list__subitem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 20px 30px 0;
    margin-left: 40px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .2s all ease-in-out;
}

/* Panel - content */
.panelContent {
    background: #F2F2F2;
    width: 80%;
    min-height: 100vh;
    overflow: scroll;
    margin-left: 20%;
    padding: 60px 40px;
}

.panelContent__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoutBtn {
    text-decoration: underline;
    color: #4A4A4A;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.logoutBtn:hover {
    opacity: .8;
}

.panelContent__header__back {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #fff !important;
    transform: translateX(-50px);
}

.panelContent__header__back__img {
    width: 35px;
    height: auto;
    margin-left: 30px;
    transition: .2s all ease-in-out;
}

.panelContent__header__back:hover .panelContent__header__back__img {
    transform: translateX(12px);
}

/* Panel - orders */
.panelContent__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    padding: 30px 0;
    border-bottom: 2px solid #E8E8E8;
}

.panelContent__column {
    padding: 0 15px;
    flex: 1;
}

.panelContent__column__label {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.panelContent__column__value {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.dateTime {
    display: block;
    text-align: center;
}

.panelContent__column__status {
    padding: 4px 14px;
    background: var(--main) !important;
    height: 30px;
    border-radius: 30px;
    color: #fff !important;
}

.panelMenu__list {
    height: calc(100vh - 170px);
    overflow: scroll;
}

.status--positive {
    background: #BCFFD9;
    border: 1px solid #12944B;
    color: #12944B;
    font-size: 12px;
    text-transform: uppercase;
}

.status--negative {
    background: #FFBCBC;
    border: 1px solid #941212;
    color: #941212;
    font-size: 12px;
    text-transform: uppercase;
}

.panelContent__column__btn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.panelContent__column__value--buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__column__icon {
    width: 15px;
    height: auto;
    margin: 0 10px;
}

.panelContent__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 40px;
}

.categoryList {
    margin-top: 50px;
}

.panelContent__filters__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
}

.panelContent__filters__label {
    margin-right: 20px;
    color: #fff;
}

.panelContent__input {
    padding: 5px 10px;
    border: none;
    outline: none;
    background: transparent;
}

.panelContent__input::placeholder {
    color: #757575;
}

.panelContent__sortBtn {
    background: transparent;
    color: #757575;
    font-size: 14px;
    border: none;
    margin: 0 15px;
    cursor: pointer;
}

.panelContent__sortBtn--active {
    text-decoration: underline;
}

.panelContent__filters__label__label {
    color: #757575;
    font-size: 14px;
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addProduct__categorySelect .panelContent__filters__label__label {
    justify-content: flex-start;
}

.panelContent__filters__btn {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    margin-right: 7px;
    background: #fff;
    cursor: pointer;
    padding: 4px;
}

.d-none {
    display: none;
}

.panelContent__filters__btn--active {
    background: var(--main);
    display: block;
    width: 100%;
    height: 100%;
}

.panelContent__input__label {
    border: 1px solid #fff;
}

.panelContent__input__span {
    padding: 0 12px;
}

.panelContent__input__icon {
    filter: brightness(0) invert(1);
}

/* Add product page */
.editor {
    background: #fff;
    border: 1px solid #000;
    padding: 0 10px;
    min-height: 300px;
}

.allergensImg {
    width: 40px;
    height: auto;
}

.editorWrapper {
    max-width: 600px;
}

.addProduct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--black-primary);
    padding: 50px;
}

.addProduct__header__h {
    text-align: left;
    font-size: 26px;
    margin-bottom: 20px;
    width: 100%;
}

.addProduct__header {
    width: 100%;
    margin: 40px 0;
    border-bottom: 1px solid #E8E8E8;
}

.admin__editorWrapper,
.addProduct__label {
    display: block;
    width: 100%;
    margin: 15px 0;
    font-size: 14px;
    color: #6B6B6B;
}

.fileInputLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.addProduct__form {
    width: 600px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__form__section {
    width: 100%;
    margin-top: -30px;
}

.addProduct__form--addProduct {
    display: flex;
    width: 80%;
    max-width: 1600px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.addProduct__input {
    padding: 5px 10px;
    width: 100%;
    height: 50px;
    opacity: 1;
    background: transparent;
    border: 1px solid #4653B1;
    border-radius: 3px;
    outline: none;
    margin-top: 4px;
}

.fileUploader {
    margin: 30px 0;
}

.addProduct__input::placeholder {
    color: #757575;
}

.addProduct__categorySelect {
    width: 100%;
    font-size: 17px;
    padding-left: 10px;
    margin-top: 0;
}

.addProduct__form__section--bottom {
    padding: 20px;
    border: 1px solid #6B6B6B;
    border-radius: 5px;
    margin-left: 40px;
    margin-top: 0 !important;
}

.addProduct__btn {
    width: 100%;
    height: 60px;
    border-radius: 60px;
    border: none;
    outline: none;
    background: var(--main);
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    transition: .4s all ease-in-out;
}

.addProduct__btn:hover {
    background: var(--hover);
}

.addProduct__label--file {
    width: 100%;
    height: 200px;
    border: 2px dashed #B4B4B4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8c8f94;
    text-transform: uppercase;
    cursor: pointer;
}

.addProduct__fileImg {
    width: 40px;
    height: auto;
    margin-bottom: 25px;
}

.addProduct__allergies {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto 50px;
}

.addProduct__allergies__item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    cursor: pointer;
    transition: .2s all ease-in-out;
    opacity: .3;
}

input[type=file] {
    display: block;
    margin: 18px auto;
    width: 200px;
}

.panelContent__productImg {
    width: 120px;
    height: auto;
}

.invisibleInput {
    visibility: hidden;
    height: 0;
}

.addProduct__form--addProduct > .addProduct__form__section > label:first-of-type {
    margin-top: 0;
}

.addProduct__form__subsection {
    display: flex;
    flex-direction: column;
}

.panelContent__filters__btn {
    margin-left: 20px;
}

.addProduct__categorySelect .panelContent__filters__btn {
    margin-left: 0;
}

.addProduct__categorySelect .panelContent__filters__label__label {
    margin-left: 0;
}

.panelContent__filters__btn--options {
    margin-right: 20px;
    margin-left: 0;
}

.panelContent__filters__btnWrapper {
    margin: 10px 0;
    cursor: pointer;
}

.marginTop30 {
    margin-top: 30px;
}

.addProduct__form__subsection--marginLeft {
    margin-left: 120px;
}

.addProduct__input--invisible {
    display: block;
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    transition: .4s all ease-in-out;
}

.jodit-container {
    margin-top: 15px;
    margin-bottom: 30px;
}

.jodit--label span {
    font-size: 14px;
}

.input--hidden {
    visibility: hidden !important;
}

.addMsg {
    margin-top: 100px;
}

/* Panel - categories */
.panelContent__contentWrapper,
.panelContent__frame {
    background: #fff;
    padding: 25px;
    margin-top: 50px;
    box-shadow: var(--shadow);
    border-radius: 10px;
}

.panelContent__frame__header {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.addedMsgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 40px;
}

.panelContent__frame__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px auto;
}

.addProduct__categorySelect {
    width: 100%;
    display: block;
}

.addProduct__label--frame {
    width: 100%;
    font-size: 13px;
    color: #8c8f94;
}

.panelContent__input__label--payment {
    margin: 10px 0;
    width: 100%;
}

.panelContent__input__label--payment .panelContent__input {
    width: 100%;
    height: 40px;
}

/* Panel - settings */
.panelContent__twoForms {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__label--frame--margin {
    margin: 10px 0;
}

.marginTop10 {
    margin-top: 10px;
}

.formError--panel {
    color: red;
    font-size: 12px;
    text-transform: none;
    padding-top: 4px;
    display: block;
}

.formMsg {
    text-align: center;
    font-size: 20px;
    margin: 30px 0;
}

/* Modal on delete */
.panelModal .ReactModal__Overlay {
    background: rgba(0, 0, 0, .6) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelModal .ReactModal__Content {
    width: 500px !important;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: var(--shadow);
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    border-radius: 10px !important;
    transform: translate(-50%, -50%);
}

.modalClose {
    background: transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: auto;
    border: none;
    cursor: pointer;
}

.modalClose__img {
    width: 100%;
    height: auto;
}

.modalQuestion {
    font-size: 18px;
    text-align: center;
    padding: 20px;
}

.modalQuestion__btn {
    margin: 0 20px;
    background: var(--main);
    width: 150px;
    height: 50px;
    border-radius: 50px;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: .4s all ease-in-out;
}

.modalQuestion__btn:hover {
    background: #000;
    color: #fff;
}

/* Panel - payment */
.labelSpan {
    color: #8c8f94;
    font-size: 13px;
    display: block;
    margin-top: 15px;
    text-align: left;
    width: 100%;
}

.paymentMethods__msg {
    margin-top: 25px;
}

/* Order details */
.panelContent__header--smaller {
    font-size: 17px;
    font-weight: 400;
}

.panelContent__header--smaller b {
    font-size: 21px;
}

.panelContent__cart__content {
    margin: 40px 0 40px 20px;
    max-width: 1000px;
    position: relative;
}

.panelContent__cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}

.panelContent__cart__column:first-of-type {
    flex: 2;
}

.panelContent__cart__column {
    flex: 1;
}

.panelContent__cart__column:last-of-type {
    flex: 2;
}

.panelContent__cart__item span {
    font-size: 15px;
    font-weight: 700;
}

.panelContent__cart__sum {
    position: absolute;
    bottom: -100px;
    right: 0;
    transform: translateX(0);
}

.panelContent__cart__summary {
    text-align: right;
    font-size: 18px;
    max-width: 1000px;
    margin-left: 20px;
}

.panelContent__cart__summary span {
    font-size: 15px;
    font-weight: 400;
}

.panelContent__cart__column {
    text-align: right;
}

.panelContent__orderDetails {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top: 120px;
}

.panelContent__clientData {
    width: 45%;
    margin-right: 50px;
}

.panelContent__shipping {
    width: 45%;
}

.panelContent__clientData__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
}

.panelContent__data {
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #000;
    padding: 0 20px;
    color: #757575;
    margin: 8px 0;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 48%;
}

.w-70 {
    width: 58%;
}

.w-30 {
    width: 38%;
}

.w-20 {
    width: 20%;
}

.panelContent__orderStatus__header {
    margin-top: 30px;
    font-size: 20px;
    justify-content: flex-start;
}

.panelContent__orderStatus__img {
    margin-left: 10px;
    width: 20px;
    height: auto;
}

.panelContent__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__cart__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panelContent__btn {
    margin: 0 20px;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    transition: .4s all ease-in-out;
}

.btn--red {
    border: 1px solid #941212;
    background: #FFBCBC;
    color: #941212;
    padding: 5px 10px;
}

.btn--red:hover {
    background: #941212;
    color: #fff;
}

.btn--neutral {
    background: #BCFFD9;
    color: #12944B;
    border: 1px solid #12944B;
    transition: .4s all ease-in-out;
}

.btn--neutral:hover {
    background: #12944B;
    color: #fff;
}

.button--link {
    background: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.panelContent__buttons--orderDetails .panelContent__btn {
    height: 35px;
    padding: 10px 15px;
}

.product__fileInput {
    margin-bottom: 50px !important;
}

.allergiesChecked {
    opacity: 1;
}

/* Start panel page */
.panelContent__startContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    height: 60vh;
}

.panelContent__start__item {
    border: 2px solid #F1F1F1;
    background: var(--black-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 29%;
    padding: 70px 30px;
    margin-bottom: 30px;
    opacity: .7;
    transition: .3s all;
}

.panelContent__start__item:hover {
    opacity: 1;
}

.panelContent__start__item__img {
    width: 90px;
    height: auto;
}

.panelContent__start__item__header {
    margin-top: 25px;
}

.settingsForm {
    width: 48%;
    max-width: 500px;
}

.panelContent__frame__main {
    margin-top: 30px;
}

.panelContent__ribbons__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    border: 1px solid black;
}

.panelContent__ribbons__column {
    font-weight: 400;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 0;
}

.panelContent__ribbons__column--caption {
    border-left: 1px solid #000;
    padding-left: 10px;
}

.panelContent__filters__label__label--category {
    margin-bottom: 20px;
    margin-top: 15px;
    width: 100%;
    justify-content: flex-start;
}

.panelContent__filters__label__label--category .panelContent__filters__btn {
    margin-left: 10px;
}

/* Blog */
.addProduct__form--addPost {
    flex-direction: column;
}

.addProduct__form--addPost, .panelContent__addPost,
.panelContent__addPost .jodit--label
{
    width: 100%;
}

.panelContent__addPost .jodit-workplace {
    min-height: 500px !important;
}

.addProduct__label--addPost {
    width: 100%;
    margin-bottom: 30px;
}

.en-division {
    display: block;
    margin: 40px 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, .25);
}

/* Others */
.panelContent--others {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.panelContent__othersSection {
    width: 45%;
}

.panelContent__ribbons__column__img {
    width: 20px;
}

.shippingForm {
    width: 100%;
}

.btn--maxWidth {
    max-width: 400px;
}

.d-flex .jodit--label {
    width: 45%;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.errorsContainer {
    color: red;
    font-size: 13px;
    margin-bottom: 10px;
}

.cart--flex--admin {
    flex-wrap: wrap;
}

.cart--flex--admin .addProduct__btn {
    max-width: 400px;
    margin: 20px auto;
}

.marginTop20 {
    margin-top: 20px;
    display: block;
}

.w-100 {
    width: 100%;
}

.smaller {
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}

.jodit, .jodit *, .jodit-container, .jodit-container * {
    color: #000 !important;
}

/* Modifications */
.d-flex > .addProduct__input {
    width: 47%;
}

.inPost__address__header {
    font-size: 17px;
    margin-bottom: 5px;
}

.inPost__address {
    color: #000;
    margin: 20px 0;
}

.panelContent__filters__btnWrapper:last-child .panelContent__filters__btn {
    margin-left: 15px;
}

.addShipping__input {
    max-width: 500px;
    margin: 10px 0;
}

.btn--editSectionHeader {
    max-width: 400px;
}

.pl-5 {
    padding-left: 25px;
}

.addProduct__input--textarea {
    height: 100px;
    margin-bottom: 0;
    resize: none;
}

.btn--shipping {
    margin-top: 10px;
}

/* Update - 20.08.21 */
.mainProductImage, .galleryProductImage {
   height: 150px;
    width: auto;
    margin: 15px;
    cursor: pointer;
}

.galleryProductImage--main {
    border: 3px solid #000;
}

.galleryWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

/* Stocks */
.addProduct--stock {
    align-items: flex-start;
}

.stockForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.chooseProducts {
    margin-left: 80px;
}

.panelContent--productList__item {
    color: #fff !important;
    margin-left: 0;
    justify-content: flex-start;
}

.panelContent--productList__item .panelContent__filters__btn {
    margin-left: 0;
}

.stockTitleLabel {
    width: 100%;
    margin: 30px auto;
    font-size: 14px;
}

.stockTitleInput {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border: 1px solid #fff;
    background: transparent;
    padding: 10px;
    outline: none;
}

.infoHeader--stock {
    width: 100%;
}

/* Update - 06.09.21 */
.panelContent__input--letterNumber {
    border: 1px solid #fff;
    margin: 0 15px;
}

.panelContent__select {
    margin: 0 15px;
}

.panelContent__editOrderBtn {
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    max-width: 300px;
}

/* Update - 20.09.21 */
.panelContent__column__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    width: -moz-max-content;
    margin: auto;
}

/* Update - 04.10.21 */
.panelContent__input--adminComment {
    color: #fff;
    resize: none;
    border: 1px solid #fff;
    display: inline-block;
    margin-left: 30px;
    margin-top: 20px;
}

.panelContent__header--smaller--adminComment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Update - 19.11.21 */
.panelContent .clientForm__subheader {
    text-align: left;
    margin: 20px 0 30px;
}

.panelContent .clientForm__label {
    justify-content: flex-start;
}

.panelContent .clientForm__label,
.panelContent .clientForm__row {
    width: clamp(100px, 600px, 100%);
}

.productList__item {
    align-items: center;
}

.productList__item label {
    margin-left: 50px;
    font-size: 13px;
    text-transform: uppercase;
}

.productList__item label select {
    font-size: 16px;
}

.productList__item label select {
    margin-left: 10px;
}

.productList__item__header {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 25px;
}

.productList__btn {
    width: 200px;
    height: 50px;
    background: #707070;
    color: #fff;
    text-transform: uppercase;
    margin-left: 30px;
}

.productList__icon {
    filter: brightness(0) invert(1);
    width: 20px;
    margin: 0 20px;
}

.productList__productAdded {
    font-size: 15px;
    color: #fff;
}

.productList__productAddedWrapper {
    display: flex;
    align-items: center;
}

.panelContent--addOrder .shippingForm__products {
    background: #272727;
    padding: 40px;
    margin-top: 25px;
}

.panelContent__orderStatus__header {
    justify-content: flex-start !important;
    font-size: 15px;
}

.panelContent__orderStatus__header button {
    padding: 5px 20px;
    background: var(--main);
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    border: 1px solid #fff;
    margin-left: 25px;
    height: 35px;
    border-radius: 40px;
    cursor: pointer;
}

.panelContent--addOrder__header {
    text-align: center;
    margin-top: 20vh;
}

.loginPage {
    background: #F2F2F2;
    padding-top: 0 !important;
}

/* ------------------------------------------ */

:root {
    --shadow: 0px 0px 10px #0A0E2A26;
    --main: #0A0E2A;
    --hover: #080D19;
}

.loginPage__form {
    box-shadow: var(--shadow);
    border-radius: 10px;
    background: #fff;
}

.button--loginForm {
    background: #0A0E2A;
    height: 50px;
    border-radius: 50px;
}

.input--adminLogin {
    height: 50px;
    border-radius: 50px;
    color: #000;
    padding-left: 20px;
}

.loginPage__form .label {
    color: #000;
    font-style: italic;
    text-align: center;
}

.panelContent__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.panelContent__btn {
    color: #fff;
    background: var(--main);
    padding: 10px 30px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all;
}

.panelContent__btn:hover {
    background: var(--hover);
}

.panelContent__btn__icon {
    width: 20px;
    margin-left: 15px;
}

.response {
    position: absolute;
    top: 30%;
    right: 0;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
}

.response--positive {
    background: green;
}

.response--negative {
    background: #941212;
}

.admin__addGallery {
    color: #6B6B6B;
    font-size: 14px;
}

.addProduct__categorySelect {
    color: #6B6B6B;
    font-size: 14px;
    padding: 20px;
    border: 1px solid #6B6B6B;
    border-radius: 5px;
}

.addProduct__categorySelect .panelContent__filters__label__label:first-of-type {
    margin-top: 25px;
}

.admin__label__imgUpload__trashBtn {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .7);
    z-index: 99999;
}

.admin__label__imgUpload__trashBtn .btn__img {
    width: 40%;
}

.admin__label__imgUpload::after {
    content: "+";
    font-size: 120px;
    text-align: center;
    color: #6B6B6B;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.admin__label__imgUpload__updateImgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 5;
}

.admin__label__imgUpload__updateImg {
    height: 190px;
    width: auto;
    max-width: 100%;
}

.admin__label__imgUpload {
    width: 300px;
    height: 200px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px dashed #6B6B6B;
    margin-top: 10px;
    position: relative;
}

.admin__label {
    font-size: 14px;
    color: #6B6B6B;
}

.dzu-inputLabel {
    opacity: 0;
}

.admin__label--imgUpload {
    cursor: pointer;
    margin-bottom: 30px;
}

.admin__flex .dzu-previewImage {
    max-width: 290px;
    max-height: 190px;
    margin: 0;
}

.dzu-previewStatusContainer {
    display: none !important;
}

.admin__label--imgUpload--section {
    width: 60%;
}

.alignTop {
    align-items: flex-start !important;
}

.rug .rug-items.__card {
    min-height: 40px !important;
}

.addProduct__bottom {
    margin-top: 40px;
}

.panelContent__column--img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
}

.mt-4 {
    margin-top: 20px;
}

.center {
    justify-content: center !important;
}

.center .admin__label--imgUpload--section {
    width: auto;
    margin: 0 20px;
}

.panelContent__editOrderBtn {
    background: #2B113E;
    color: #fff;
    height: 40px;
    border-radius: 40px;
    cursor: pointer;
}

.infoHeader {
    margin-top: 12px;
    font-weight: 400;
    font-size: 15px;
}

.panelStart__btn {
    background: #F2F2F2;
    width: 175px;
    height: 35px;
    border-radius: 35px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__frame--orders {
    width: 60%;
}

.panelContent__frame--stats {
    width: 37%;
}

.panelContent__startContent {
    align-items: flex-start;
    margin-top: 0;
}

.panelContent__startContent .panelContent__column__key,
.panelContent__startContent .panelContent__column__value {
    font-size: 12px;
    margin: 0 !important;
}

.panelContent__startContent .orderItem {
    padding: 5px 0;
}

.panelContent__startContent .panelContent__column__status {
    font-size: 10px;
    height: 25px;
    margin-top: 5px;
}

.panelContent__startContent .panelContent__column__label {
    margin-bottom: 5px;
}

.panelStart__subheader {
    margin: 10px 0;
}

.panelStart__stats {
    max-width: 300px;
}

.panelStart__stats > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.panelStart__header--big {
    font-size: 28px;
}

.panelContent__frame--quick {
    margin-top: 20px;
    height: 140px;
    margin-bottom: 140px;
}

.quickBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quickBtn .icon {
    width: 25px;
    margin: 0 0 10px 0;
    filter: brightness(0);
}

.mt {
    margin-top: 20px !important;
}

.panelContent--customFields .addProduct__input {
    margin-bottom: 30px;
}

.twoImages {
    justify-content: space-evenly !important;
}


.videoUploaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
    transition: .4s all;
}

.videoUploader {
    background: #fff;
    border-radius: 30px;
    width: clamp(200px, 80vw, 1200px);
    height: 700px;
    max-height: 80vh;
    position: relative;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videoLoadingWrapper {
    margin-top: 7%;
}

.videoLoadingWrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressBar {
    width: 400px;
    height: 10px;
    border-radius: 10px;
    display: block;
    margin: 60px auto 0;
    background: var(--dark);
    position: relative;
    overflow: hidden;
}

.progressBar__inner {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s all;
    background: var(--gold);
}

.progressBar__percent {
    font-size: 20px;
    color: #313131;
    font-weight: 700;
    font-family: 'Oxanium', cursive;
    text-align: center;
    display: block;
    margin-top: 60px;
}

.player__header--videoUploader {
    position: absolute;
    top: 50px;
    left: 80px;
}

.videoUploader__imgWrapper {
    background: transparent;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoUploader__img {
    width: 50%;
}

.videoUploader__text {
    text-align: center;
    color: #3d3d3d;
    margin: 20px 0;
    font-size: 28px;
    max-width: 400px;
}

.videoUploader__smallText {
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    max-width: 500px;
}

.button--videoUploader {
    width: 400px;
    max-width: 80%;
}

.videoUploader .dzu-dropzone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    opacity: 0;
    cursor: pointer;
}

.dzu-inputLabel {
    cursor: pointer;
}

.videoUploader .dzu-dropzone::after {
    display: none;
}

.videoUploader__responseHeader {
    color: #3d3d3d;
    text-align: center;
    margin-top: 30px;
}

.registerModal__closeBtn {
    background: transparent;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    outline: none;
    z-index: 100;
    width: 30px;
    cursor: pointer;
}

.registerModal__closeBtn__img {
    width: 100%;
    height: auto;
}

.dzu-inputLabel {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 11;
    opacity: 0;
}

.button--videoUploader {
    padding: 10px 20px;
    height: 50px;
    border-radius: 50px;
    background: var(--main);
    color: #fff;
    font-weight: 700;
}

.panelContent--customFields .panelContent__frame {
    position: relative;
    z-index: 10;
}

.addVideoBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border-radius: 40px;
    color: #fff;
    background: var(--main);
    cursor: pointer;
    border: none;
    outline: none;
}

.videoTable__item__miniature {
    max-width: 80%;
}

.mediaType {
    margin-top: 30px;
}

.mediaType label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.mediaType__btn {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.mediaType__btn span {
    display: none;
}

.mediaType__btn--selected span {
    background: var(--main);
    display: block;
    width: 15px;
    height: 15px;
}

.align-top > .admin__label--imgUpload {
    width: auto;
}

.align-top {
    margin: 10px 0 40px;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin--grey {
    font-size: 14px;
    color: #6B6B6B;
}

.align-top > * {
    margin-right: 10%;
    margin-top: 4px;
}

.admin--grey .video-react {
    overflow: hidden;
}

.align-top .videoTable__item__miniature {
    width: 100%;
    max-width: 100%;
}
