@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}

html {
    scroll-behavior: smooth;
}

:root {
    --main: #361D48;
    --hover: #250C37;
    --text: #342A51;
}

.w {
    width: clamp(200px, 90vw, 1600px);
    max-width: 90vw;
    margin: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trans {
    background: transparent;
    border: none;
    outline: none;
}

.btn__img {
    width: 100%;
    height: auto;
}

.container {
    width: clamp(200px, 90vw, 1600px);
    max-width: 90vw;
    margin: auto;
}

.container .siteHeader {
    padding-top: 30px;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 5;
    transition: .3s all;
}

.siteHeader__logoWrapper {
    display: block;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    transform-origin: top left;
    transition: .3s all;
}

.siteHeader__right {
    width: 240px;
    height: 50px;
}

.siteHeader__right__item {
    width: 130px;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
}

.siteHeader__right__item .btn__img {
    width: auto;
    height: 30px;
    margin-bottom: 5px;
    transition: .2s all;
}

.siteHeader__right__item:hover .btn__img {
    transform: translateY(-2px);
}

.topMenu {
    border-bottom: 1px solid #CBCBCB;
    padding: 40px 5% 7px;
    margin-top: 170px;
}

.topMenu__list {
    list-style-type: none;
    margin: auto;
    width: 90%;
}

.topMenu__list__link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: var(--main);
    transition: .2s all;
}

.topMenu__list__link:hover {
    color: inherit;
    transform: translateY(-3px);
}

.slider {
    margin: 30px auto;
}

.row__header {
    margin: 30px auto;
    text-align: center;
    color: var(--main);
    font-size: 32px;
    text-transform: uppercase;
}

.preview {
    width: 100%;
}

.preview__title {
    font-size: 18px;
    margin: 10px 0;
    color: var(--main);
}

.preview__subtitle {
    font-weight: 400;
    font-size: 14px;
}

.preview__price {
    margin: 15px 0;
    color: var(--main);
    font-size: 24px;
}

.addToCartBtn {
    background: var(--main);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    height: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: .2s all;
}

.addToCartBtn:hover {
    background: var(--hover);
}

.addToCartBtn__img {
    width: 12px;
    margin-left: 10px;
}

.preview__imgWrapper {
    width: 350px;
    max-width: 100%;
    height: 390px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.preview__imgWrapper .btn__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .4s all;
    height: 100%;
    width: auto;
    min-width: 100%;
}

.previewImg2 {
    opacity: 0;
}

.preview__imgWrapper:hover .previewImg1 {
    opacity: 0;
}

.preview__imgWrapper:hover .previewImg2 {
    opacity: 1;
}

.infoSection {
    background: #EBE8ED;
    margin: 60px auto;
}

.infoSection--2 {
    height: 500px;
}

.infoSection--3 {
    height: 485px;
}

.infoSection__imgWrapper {
    width: 600px;
    height: 650px;
}

.infoSection__imgWrapper--2 {
    width: 825px;
    height: 500px;
}

.infoSection__imgWrapper--3 {
    width: 800px;
    height: 485px;
}

.infoSection__content__main {
    height: auto;
}

.infoSection__content__main h1,
.infoSection__content__main h2,
.infoSection__content__main h3,
.infoSection__content__main h4 {
    color: var(--main) !important;
}

.infoSection__content {
    width: calc(100% - 600px);
    padding-left: 30px;
    padding-right: 30px;
}

.infoSection--2 .infoSection__content {
    width: calc(100% - 825px);
}

.infoSection--3 .infoSection__content {
    width: calc(100% - 800px);
}

.infoSection--2 .infoSection__content__main {
    height: auto;
}

.infoSection--3 .infoSection__content__main {
    height: auto;
}

.infoSection__btn {
    background: var(--main);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.infoSection__btn--100 {
    height: 60px;
}

.arrowIcon {
    width: 15px;
    margin-left: 20px;
    transition: .2s all;
}

.infoSection__btn:hover .arrowIcon {
    transform: translateX(7px);
}

.row--popular {
    margin-top: 100px;
}

.infoSection--2 {
    margin-bottom: 25px;
}

.aboutUs {
    margin-top: 60px;
    padding-top: 5px;
    border-top: 1px solid #E3E3E3;
}

.merits__item {
    flex-direction: column;
    width: 15%;
}

.merits__icon {
    width: 50px;
}

.merits__header {
    margin: 20px auto;
    font-size: 16px;
    text-align: center;
}

.merits__text {
    text-align: center;
    font-size: 12px;
}

.merits__text, .merits__header {
    color: var(--main);
}

.newsletter {
    border-top: 1px solid #E3E3E3;
    margin: 50px auto 30px;
    padding: 25px 0 0;
    transition: .3s all;
}

.newsletter__form {
    position: relative;
    justify-content: flex-end;
}

.newsletter__header {
    font-size: 36px;
    color: var(--main);
    padding-right: 60px;
    border-right: 1px solid #E3E3E3;
}

.d-block {
    display: block;
}

@media(min-width: 1800px) {
    .newsletter__text {
        margin-left: -10%;
    }
}

.newsletter__text {
    margin-left: -8%;
    color: var(--main);
}

.newsletter__form label {
    width: auto;
}

.newsletter__input {
    width: 500px;
    height: 50px;
    padding: 5px 15px;
    border: 2px solid #000;
}

.newsletter__submitBtn {
    background: var(--main);
    width: 175px;
    height: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    margin-left: 15px;
    cursor: pointer;
}

.newsletter__submitBtn:hover .arrowIcon {
    transform: translateX(7px);
}

.newsletter__success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: -1;
    transition: .3s opacity;
    font-size: 14px;
    text-align: center;
}

label {
    position: relative;
}

.error {
    max-width: 100%;
    position: absolute;
    bottom: -30px;
    font-size: 13px;
    color: red;
    left: 0;
    font-weight: 400;
    width: 500px;
}

.footer {
    border-top: 1px solid #E3E3E3;
    padding-top: 30px;
}

.footer__bottom {
    border-top: 1px solid #E3E3E3;
    padding: 15px 0;
    flex-direction: column;
}

.footer__bottom__header {
    color: #6B6B6B;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.footer__header {
    font-size: 14px;
    margin-bottom: 7px;
    color: var(--main);
}

.footer__text {
    color: #6B6B6B;
    font-size: 14px;
    display: block;
    margin: 3px 0;
}

.footer__text a:hover {
    opacity: .8;
}

.footer__main {
    padding-bottom: 25px;
    align-items: flex-start;
}

.footer__col__content {
    margin-left: 45px;
}

.footer__text--big {
    font-weight: 700;
    font-size: 26px;
    margin: 3px 0 8px;
}

.page {
    max-width: 95%;
    margin: 40px auto;
    overflow: hidden;
}

.page__header {
    color: var(--text);
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
}

.form__header {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 3px;
    border-bottom: 1px solid #F2F2F2;
    color: #6B6B6B;
}

.form {
    max-width: 500px;
    margin: 0 auto 60px;
}

html {
    scroll-behavior: smooth;
}

label {
    width: 100%;
    margin: 4px 0;
    display: block;
}

.input {
    transition: .2s all;
    width: 100%;
    border: 2px solid var(--main);
    padding: 5px 12px;
    height: 50px;
}

.form__header--2 {
    margin-top: 20px;
}

.form__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.noProductsWrapper .btn--back {
    margin-left: 0;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
}

.label--name {
    width: 40%;
}

.label--surname {
    width: 57%;
}

.label--postalCode {
    width: 35%;
}

.label--city {
    width: 63%;
}

.label--street {
    width: 50%;
}

.label--flat,
.label--building {
    width: 23%;
}

.icon {
    width: 15px;
    margin-left: 15px;
}

.btn--submit {
    width: 100%;
    height: 50px;
    margin-top: 7px;
    margin-bottom: 90px;
}

.btn .icon {
    transition: .2s all;
}

.btn:hover .icon {
    transform: translateX(7px);
}

.input::placeholder {
    font-size: 12px;
}

.page--ty {
    margin: 100px auto;
}

.ty__header, .ty__subheader {
    text-align: center;
    font-size: 24px;
    max-width: 700px;
    margin: auto;
}

.ty__subheader {
    font-size: 17px;
    font-weight: 400;
    margin-top: 20px;
}

.btn--back {
    margin: 30px auto;
    width: 300px;
    max-width: 100%;
    height: 50px;
    transition: .2s all;
}

.btn--back:hover {
    background: var(--hover);
}

.input--error {
    border-color: red;
}

.label--check {
    color: #6B6B6B;
    font-size: 12px;
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.checkBtn {
    width: 15px;
    height: 15px;
    background: transparent;
    border: 2px solid var(--main);
    margin-right: 10px;
    position: relative;
}

.checkBtn img {
    opacity: 0;
    width: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkBtn--checked img {
    opacity: 1;
}

.afterLogin {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 13px;
    background: transparent;
    border: none;
    outline: none;
    width: 500px;
    margin: auto;
    display: block;
    text-align: left;
}

button.afterLogin,
.afterLogin a {
    text-decoration: underline;
    cursor: pointer;
    transition: .2s all;
}

button.afterLogin {
    margin-bottom: 4px;
}

button.afterLogin:hover,
.afterLogin a:hover {
    opacity: .8;
}

.page--login .form,
.page--login .btn--submit {
    margin-bottom: 12px;
}

h4.afterLogin {
    margin-bottom: 100px;
}

.page--login .error,
.page--register .error {
    width: 500px;
    margin: auto;
    max-width: 100%;
    position: relative;
    bottom: 0;
    margin-top: -70px;
    text-align: center;
}

.page--login .error {
    margin-top: 20px;
    margin-bottom: 30px;
}

.menu,
.cart {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    max-width: 700px;
    height: 100vh;
    background: #fff;
    z-index: 100;
    padding: 40px 30px;
    transition: .2s all;
    transform: scaleX(0);
    transform-origin: top right;
}

.background {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: .2s opacity;
}

.siteHeader__menuBtn {
    cursor: pointer;
}

.menu > *,
.cart > * {
    opacity: 0;
    transition: .2s all;
}

.cart {
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.panelContent::-webkit-scrollbar,
.menu__search--mobile::-webkit-scrollbar,
.panelMenu__list::-webkit-scrollbar,
.menu__main::-webkit-scrollbar,
.cart::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.panelContent::-webkit-scrollbar,
.menu__main,
.panelMenu__list,
.menu__search--mobile,
.cart {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.cart__item__attribute {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 13px;
    display: block;
}

.menu {
    right: unset;
    left: 0;
    width: 1250px;
    transform-origin: top left;
    max-width: 95vw;
}

.cart__header {
    font-size: 32px;
    color: var(--main);
    padding-bottom: 12px;
    border-bottom: 1px solid #CBCBCB;
}

.cart__close {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 12px;
    background: transparent;
    border: none;
    outline: none;
    filter: invert(.5);
    cursor: pointer;
}

.cart__sum h4 {
    color: #6B6B6B;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #CBCBCB;
}

.menu__search--mobile .noProducts {
    text-align: center;
    padding-top: 30px;
}

.cart__sum h5 {
    font-size: 16px;
    font-weight: 400;
}

.btn--cart {
    width: 100%;
    height: 50px;
}

.cart__item {
    margin: 15px 0;
    padding: 15px 0;
    border-bottom: 1px solid #E3E3E3;
}

.cart__item__count {
    border: 1px solid var(--main);
    padding: 4px 6px;
    font-size: 14px;
    text-align: center;
    margin: 0 9px;
    font-weight: 400;
    min-width: 25px;
}

.cart__item__imgWrapper {
    width: 120px;
    max-height: 220px;
    overflow: hidden;
}

.cart__item__img {
    width: 100%;
    height: auto;
    border: 2px solid var(--main);
}

.cart__item__title {
    font-size: 13px;
    font-weight: 500;
    margin-left: 20px;
    max-width: 250px;
}

.cart__item__firstCol {
    max-width: 220px;
    justify-content: flex-start;
}

.cart__item__btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    transition: .2s all;
}

.cart__item__btn:hover {
    opacity: .8;
}

.cart__item__price {
    font-weight: 400;
    font-size: 16px;
}

.page--beforeCheckout > main {
    margin-top: 5%;
    align-items: flex-start;
    justify-content: center;
}

.page--beforeCheckout > main > div {
    width: 44%;
}

.page--beforeCheckout > main > div:first-of-type {
    padding-right: 5%;
}

.page--beforeCheckout > main > div:last-of-type {
    padding-left: 5%;
}

.beforeCheckoutDivider {
    width: 1px;
    height: 300px;
    background: #CBCBCB;
    display: block;
}

.page--beforeCheckout > main ul,
.page--beforeCheckout > main li,
.page--beforeCheckout > main p {
    color: #6B6B6B;
    font-size: 14px;
    margin: 10px 0;
    max-width: 500px;
}

.page--beforeCheckout > main li {
    margin: 3px 0;
}

.page__header--left {
    text-align: left;
}

.page--beforeCheckout > main ul {
    list-style-type: disc;
    margin-left: 20px;
}

.page--beforeCheckout > main p a {
    text-decoration: underline;
}

.btn--continue {
    max-width: 500px;
    margin-top: 20px;
}

.page--beforeCheckout__backWrapper {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: clamp(200px, 90vw, 1600px);
}

.page--beforeCheckout__back {
    text-decoration: underline;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    width: fit-content;
    width: -moz-max-content;
    opacity: .75;
}

.page--beforeCheckout__back:hover {
    opacity: 1;
}

.page--beforeCheckout__back img {
    margin: 0 8px 0 0;
    width: 11px;
}

.menu {
    padding: 35px 6%;
}

.cart__close--menu {
    width: 20px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
}

.menu label {
    width: 300px;
}

.searchInput {
    width: 100%;
    height: 35px;
    border: 2px solid var(--main);
    padding: 5px 10px;
}

.menu .page__header {
    text-align: left;
    margin-top: 20px;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
}

.menu__main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    height: 80%;
    padding-left: 20px;
}

.menu__main a {
    font-size: 15px;
    transition: .1s all;
}

.menu__main a:hover {
    opacity: .7;
    transform: translateX(2px);
}

.menu__main__categoryBlock {
    margin-bottom: 20px;
    margin-right: 40px;
}

.menu__main__category {
    font-weight: 700;
    position: relative;
}

.menu__main__category::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    width: 15px;
    height: 2px;
    background: #E3E3E3;
    transform: translateY(-50%);
}

.menu__main__categoryBlock > ul {
    list-style-type: disc;
    margin-left: 20px;
}

.menu__main__subcategory {
    display: block;
    margin: 5px 0;
}

.products {
    align-items: flex-start;
    justify-content: flex-start;
}

.products > * {
    margin-right: 60px;
}

.cartPage__main {
    margin-top: 30px;
}

.cart__sum--cartPage {
    justify-content: flex-end;
}

.cart__sum--cartPage h4 {
    margin-right: 30px;
}

.cartPage__main .cart__item__firstCol {
    max-width: none;
}

.cartPage__main .cart__item__imgWrapper {
    width: 150px;
}

.cartCol__key, .cartCol__value {
    text-align: center;
    font-weight: 400;
}

.cartCol__key {
    font-size: 12px;
    color: #6B6B6B
}

.cartCol__value {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}

.cartCol__btn {
    background: transparent;
    width: 16px;
    cursor: pointer;
    opacity: .7;
    border: none;
    outline: none;
    transition: .2s all;
}

.cartCol__btn:hover {
    opacity: 1;
}

.cartPage__main .emptyCart__header {
    text-align: center;
}

.emptyCart--right {
    margin-top: 30px;
}

.container--deliveryData .cart {
    box-shadow: -3px 0px 5px #0000000D;
}

.deliveryData__header {
    font-size: 32px;
    margin: 30px 0;
}

.form--deliveryData {
    margin-left: 0;
}

.deliveryData__bottom > * {
    position: relative;
    left: 0;
    top: 0;
}

.deliveryData__bottom .page--beforeCheckout__back:first-of-type {
    margin-bottom: 10px;
}

.container--deliveryData .btn--cart,
.container--deliveryData .cart__close {
    display: none;
}

.container--deliveryData .cart__sum h4 {
    border-bottom: none;
    padding-top: 0;
}

.container--deliveryData .cart__sumWrapper {
    border-bottom: 1px solid #E3E3E3;
}

.container--sAndP .deliveryData {
    max-width: 53%;
}

.container--sAndP .deliveryData__bottom * {
    text-align: right;
}

.container--sAndP .page--beforeCheckout__back {
    justify-content: flex-end;
}

.btn--payment {
    display: none;
}

.container--sAndP .btn--payment {
    display: flex;
    margin-top: 20px;
}

.container--sAndP .cart__sum:not(.cart__sum--noBorder) {
    border-top: 1px solid #E3E3E3;
    padding-top: 10px;
}

.cart__sum--noBorder {
    display: none;
    border: none;
}

.container--sAndP .cart__sum--noBorder {
    display: flex;
}

.delivery__desc {
    color: #6B6B6B;
    font-size: 12px;
    margin-top: 5px;
}

.delivery__title {
    font-weight: 400;
    font-size: 17px;
}

.delivery__price {
    font-size: 16px;
}

.sAndP__main .label--check {
    align-items: flex-start;
    justify-content: space-between;
}

.label__deliveryBlock {
    display: block;
    margin-top: -5px;
}

.label--check--delivery {
    padding-top: 10px;
    flex-wrap: wrap;
}

.sAndP__main {
    max-width: 600px;
}

.sAndP__main .label--check .label__deliveryBlock {
    width: calc(100% - 30px);
}

.form__header--mt {
    margin-top: 50px;
}

.bigModal {
    position: relative;
    z-index: 10000;
}

.hidden {
    visibility: hidden;
    height: 0;
    margin: 0;
}

.myAccount__header {
    font-size: 18px;
    color: var(--main);
    margin-bottom: 20px;
}

.myAccount__section {
    width: 35%;
    margin-bottom: 50px;
}

.myAccount__main {
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
}

.btn--myAccount {
    margin-top: 10px;
    font-size: 15px;
}

.single__gallery {
    width: 40%;
}

.single__mainInfo {
    width: 57%;
}

.single__header {
    padding-bottom: 5px;
    border-bottom: 1px solid #E3E3E3;
}

.single__header * {
    color: var(--main);
}

.single__title {
    font-size: 32px;
    font-weight: 600;
}

.single__price {
    font-size: 36px;
    font-weight: 700;
}

.single__desc {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.7em;
}

.single__icons__item {
    width: 25px;
    margin-right: 10px;
}

.smallHeader {
    color: #6B6B6B;
    font-size: 12px;
    font-weight: 400;
    margin-right: 25px;
}

.single__row {
    margin-top: 20px;
    justify-content: flex-start;
}

.single__icons {
    margin-right: 50px;
}

.addToCartBtn--single {
    margin-top: 20px;
}

.singleTop, .singleMiddle, .singleBottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}

.inPostAddress {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.locationIcon {
    width: 30px;
    margin-right: 15px;
}

.codeForm {
    margin-bottom: 40px;
    margin-top: 10px;
}

.codeForm label {
    width: 70%;
    max-width: 300px;
    height: 40px;
    margin-right: 20px;
}

.input--code {
    height: 40px;
    margin-top: 0;
}

.codeForm {
    justify-content: flex-start !important;
}

.btn--code {
    width: 28%;
    min-width: 200px;
    height: 40px;
    transition: .2s all;
}

.btn--code:hover {
    background: var(--hover);
}

.codeForm .icon {
    margin-left: 0 !important;
}

.codeHeader {
    font-weight: 400;
    font-size: 15px;
    margin-left: 10px;
}

.galleryModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .4s opacity;
}

.closeModalBtn {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    filter: brightness(0) invert(.8);
}

.modalImgWrapper {
    max-width: 80%;
    max-height: 80vh;
    transition: .3s all;
}

.modal__arrow {
    width: 25px;
    height: auto;
    background: transparent;
    border: none;
    outline: none;
    opacity: .75;
    transition: .2s all;
}

.modal__arrow:hover {
    opacity: 1;
}

.modal__arrow--next {
    transform: rotateY(180deg);
}

.single__gallery__bottom__item,
.single__gallery__main {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
}

.single__gallery__bottom,
.single__top {
    align-items: flex-start !important;
}

.single__gallery__bottom__item {
    width: 30%;
    height: 170px;
    overflow: hidden;
}

.single__gallery__bottom {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #E3E3E3;
}

.single__gallery__bottom__item .btn__img {
    height: 100%;
    width: auto;
}

.singleBottom__section,
.singleMiddle {
    margin: 30px auto;
    padding: 20px 5%;
    border-top: 1px solid #E3E3E3;
    justify-content: center !important;
}

.singleMiddle > * {
    width: 50%;
}

.singleMiddle__imgWrapper {
    margin-right: 4%;
}

.page--single button {
    cursor: pointer;
}

.modal__arrow {
    cursor: pointer;
}

.modal__arrow--prev {
    transform: rotate(-90deg);
}

.modal__arrow--next {
    transform: rotate(90deg);
}

.infoSection ul,
.singleMiddle ul, .singleBottom ul {
    list-style-type: disc;
    margin-left: 20px;
}

.singleBottom__section {
    border: none;
}

.singleBottom__section .single__desc {
    width: 60%;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

.loader {
    width: 100%;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader__img {
    width: 100px;
    height: auto;
    animation: pulse 1.5s infinite;
}

.container--single .row--popular {
    padding-bottom: 50px;
}

.single__icons--attributeSection {
    justify-content: flex-start;
    margin-top: 15px;
}

.products,
.row > main.flex {
    display: grid;
    grid-template-columns: repeat(4, 22%);
    grid-row-gap: 40px;
    grid-column-gap: 4%;
}

.noProductsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.noProducts {
    font-weight: 400;
    width: 100%;
}

.myAccount__section .noProducts {
    margin-bottom: 15px;
}

.singleOrder {
    padding: 10px 0;
    border-bottom: 1px solid #E3E3E3;
}

.singleOrder__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.singleOrder__key {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 6px;
}

.singleOrder__value {
    font-size: 14px;
    font-weight: 500;
}

.singleOrder__btn {
    background: transparent;
    border: none;
    outline: none;
    width: 15px;
    display: block;
    opacity: .78;
    transition: .2s all;
}

.singleOrder__btn:hover {
    opacity: 1;
}

.container--orderHistory > main {
    margin: 50px auto;
}

.container--orderHistory .singleOrder {
    max-width: 700px;
    margin: auto;
}

.opacity-0 {
    opacity: 0;
}

.siteHeader__menuBtn, .siteHeader__right {
    transition: .2s all;
}

.infoSection .btn__img {
    min-height: 100%;
}

.banner {
    max-height: 500px;
    overflow: hidden;
}

.banner img {
    transition: .25s all;
}

.banner:hover img {
    transform: scale(1.02);
    filter: brightness(.9);
}

.afterSlider__imgWrapper:hover .btn__img {
    transform: scale(1.02);
}

.afterSlider__imgWrapper .btn__img {
    transition: .2s all;
}

.banner__img {
    width: 100%;
    height: auto;
}

.d-mobile {
    display: none;
}

.hero__btn {
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: none;
    outline: none;
    opacity: .7;
    cursor: pointer;
    transition: .2s all;
}

.hero__btn:hover {
    opacity: 1;
}

.hero__btn__img {
    width: 30px;
    height: auto;
}

.hero__btn--prev {
    left: 2%;
}

.hero__btn--prev .hero__btn__img {
    transform: rotate(-90deg);
}

.hero__btn--next .hero__btn__img {
    transform: rotate(90deg);
}

.hero__btn--next {
    right: 2%;
}

.slider {
    position: relative;
}

.afterSlider {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
}

.afterSlider__imgWrapper {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.row,
.infoSection {
    position: relative;
}

.infoSection {
    min-height: 400px;
}

.anchor {
    position: absolute;
    top: -230px;
    left: 0;
}

.row .anchor {
    top: -150px;
}

.container--single select {
    padding: 5px 25px;
}

.page--beforeCheckout {
    margin: 10vh auto;
}

.label--search {
    position: relative;
}

.label--search::after {
    content: "";
    background: url("../assets/search-icon.svg");
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-size: 12px
}

.menu__search {
    opacity: 0;
    height: 0;
    transition: .2s all;
    position: absolute;
    top: 200px;
    left: 9.2%;
}

.menu__main {
    transition: .2s all;
}

.searchResult {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E3E3;
    display: block;

}

.search__price {
    margin-left: 30px;
}

.menu__search .cart__item__firstCol {
    max-width: none;
    width: 100%;
}

.mobileBar {
    position: sticky;
    top: 85px;
    left: 0;
    background: #fff;
    z-index: 9;
    overflow: hidden;
}

.searchMobile {
    position: absolute;
    top: 45%;
    left: 10%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -2;
    width: 0;
    transition: .2s all;
}

.searchMobile .searchInput {
    border: none;
    border-bottom: 1px solid #E3E3E3;
    height: 40px;
    outline: none !important;
    width: 90%;
}

.menu__search--mobile {
    background: #fff;
    left: 0;
    padding: 5%;
    top: 150px;
    overflow: scroll;
    position: fixed;
}

.menu__search--mobile .cart__item__imgWrapper {
    width: 85px;
}

.search__price {
    white-space: nowrap;
}

.page--terms {
    max-width: 100%;
}

.page__text {
    margin-top: 15px;
}

.myAccount__section {
    position: relative;
}

.myAccount__section .response {
    top: 100%;
    left: 0;
    color: #000;
    margin-top: 15px;
}

.order__bottom {
    margin-top: 10px;
    padding: 20px 0;
    border-bottom: 1px solid #E3E3E3;
}

.order__bottom__right {
    width: 20%;
    max-width: 300px;
}

.btn--order {
    font-size: 12px;
    margin-bottom: 15px;
    height: 35px;
    transition: .2s all;
}

.btn--order:hover {
    background: var(--hover);
}

.order__bottom__left *,
.order__bottom__left__header {
    font-size: 14px;
}

.order__bottom__left span {
    display: block;
}

.ty__header {
    margin: 0;
}

.ty__smallHeader {
    margin-top: 15px;
    font-size: 13px;
    color: #6B6B6B;
    font-weight: 400;
}

.animation {
    margin: 20px auto 40px;
}

.animation__logo {
    opacity: 0;
    transition: .2s all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.circle{
    height: 200px;
    width: 200px;
}

.circle-outer-border {
    position: relative;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    overflow: hidden;
}

.circle-fill{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2B113E;
}

.startAnimation {
    -webkit-animation: fillerup 3s;
    animation: fillerup 3s;
}

@-webkit-keyframes fillerup {
    0% {
        top: 200px;
    }
    100% {
        top: 0px;
    }
}

@keyframes fillerup {
    0% {
        top: 200px;
    }
    100% {
        top: 0;
    }
}

.ty__content {
    opacity: 0;
    transition: .3s all;
}

.page--ty--noMargin {
    margin-top: 20px;
}

.page--100 {
    max-width: 100%;
}

.container--shop .infoSection__btn {
    display: none !important;
}

.shop__header {
    position: relative;
}

.shop__header__btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    transition: .2s all;
}

.shop__filters .shop__header__btn:hover {
    opacity: 1;
}

.shop__arrow {
    width: 10px;
    margin-left: 5px;
}

.infoSection {
    overflow: hidden;
}

.shop__filters {
    position: absolute;
    right: 0;
    bottom: 30px;
    opacity: 0;
    transition: .2s all;
}

.shop__filters .shop__header__btn {
    margin-left: 20px;
    opacity: .7;
}

.sortBtn {
    margin-top: -50px;
}

.inPostModalWrapper {
    display: block;
    height: auto;
    visibility: visible;
}

.modalHidden {
    visibility: hidden;
    height: 0;
}

.container--registerTy .ty__header {
    margin: auto;
}

.outOfStock__header {
    font-size: 15px;
    font-weight: 600;
    color: #941212;
}

.outOfStock__btn {
    padding: 5px 20px;
}

.outOfStock__formSubmit {
    white-space: nowrap;
    padding: 5px 20px;
    margin-left: 15px;
}

.notificationForm {
    margin-top: 10px;
}

.notificationStatus {
    font-size: 15px;
    margin-top: 10px;
}

.infoSection__imgWrapper video {
    width: 100%;
    height: 100%;
}

.infoSection__imgWrapper iframe {
    width: 100%;
    height: 100%;
}

.banner video {
    height: 100%;
    width: 100%;
}
