@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
/* Global */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, li, input, button, a, label, span {
    font-family: 'Poppins', sans-serif;
}

.panelMenu img
{
    -webkit-filter: brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
}

.button {
    cursor: pointer;
    outline: none;
    border: none;
    text-transform: uppercase;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

ul {
    list-style-type: none;
}

.d-mobile {
    display: none !important;
}

/* Login page */
.loginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--black-primary);
}

.loginPage__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--black-secondary);
    border: 1px solid #c3c4c7;
    padding: 20px;
}

.label {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    font-weight: 400;
    font-size: 14px;
}

.input {
    margin: 4px 0;
    border: 1px solid #8c8f94;
    background: transparent;
}

.input--adminLogin {
    width: 300px;
    height: 50px;
    padding: 5px 10px;
}

.button--loginForm {
    background: var(--black-primary);
    color: #fff;
    font-weight: 700;
    width: 100%;
    height: 50px;
    transition: .4s all;
}

.button--loginForm:hover {
    letter-spacing: 2px;
}

.formError {
    font-size: 13px;
    color: #ee0000;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}

/* Panel */
.panel {
    background: var(--black-secondary);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

#menuItem--active {
    background: #4653B1;
}

.panelMenu__menu {
    display: block;
    background: var(--black-secondary) !important;
}

@media(max-width: 776px) {
    .panelMenu__menu {
        display: none;
    }
}

.panelMenu__top {
    margin: 50px 20px 70px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.panelMenu__top__logoWrapper {
    width: 20%;
    height: auto;
}

.panelMenu .panelMenu__top .panelMenu__top__logo {
    width: 100%;
    height: auto;
    -webkit-filter: none !important;
            filter: none !important;
}

.panelMenu__top__header {
    font-size: 14px;
    font-weight: 400;
}

.panelMenu__top__subheader {
    font-size: 16px;
}

.panelMenu {
    width: 20%;
    min-width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    background: #0A0E2A;
    background: var(--main);
}

.panelMenu * {
    color: #fff;
}

.panelMenu__list {
    margin-left: 0;
    padding-left: 0;
}

.panelMenu__list__item__img {
    width: 20px;
    height: auto;
}

.panelMenu__list__item__link {
    width: 90%;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 20px auto;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #fff !important;
    transition: .2s all ease-in-out;
}

.panelMenu__list__item__link:hover,
.panelMenu__list__subitem:hover
{
    background: #4653B1;
}

.panelMenu__list__item__img {
    margin-right: 20px;
}

.panelMenu__list__subitem {
    color: #fff !important;
}

.panelMenu__list__subitem__img {
    width: 20px;
    margin-right: 20px;
    margin-left: 40px;
}

.panelMenu__list__subitem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 20px 30px 0;
    margin-left: 40px;
    font-weight: 700;
    text-transform: uppercase;
    transition: .2s all ease-in-out;
}

/* Panel - content */
.panelContent {
    background: #F2F2F2;
    width: 80%;
    min-height: 100vh;
    overflow: scroll;
    margin-left: 20%;
    padding: 60px 40px;
}

.panelContent__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoutBtn {
    text-decoration: underline;
    color: #4A4A4A;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.logoutBtn:hover {
    opacity: .8;
}

.panelContent__header__back {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #fff !important;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
}

.panelContent__header__back__img {
    width: 35px;
    height: auto;
    margin-left: 30px;
    transition: .2s all ease-in-out;
}

.panelContent__header__back:hover .panelContent__header__back__img {
    -webkit-transform: translateX(12px);
            transform: translateX(12px);
}

/* Panel - orders */
.panelContent__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    padding: 30px 0;
    border-bottom: 2px solid #E8E8E8;
}

.panelContent__column {
    padding: 0 15px;
    flex: 1 1;
}

.panelContent__column__label {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.panelContent__column__value {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.dateTime {
    display: block;
    text-align: center;
}

.panelContent__column__status {
    padding: 4px 14px;
    background: #0A0E2A !important;
    background: var(--main) !important;
    height: 30px;
    border-radius: 30px;
    color: #fff !important;
}

.panelMenu__list {
    height: calc(100vh - 170px);
    overflow: scroll;
}

.status--positive {
    background: #BCFFD9;
    border: 1px solid #12944B;
    color: #12944B;
    font-size: 12px;
    text-transform: uppercase;
}

.status--negative {
    background: #FFBCBC;
    border: 1px solid #941212;
    color: #941212;
    font-size: 12px;
    text-transform: uppercase;
}

.panelContent__column__btn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.panelContent__column__value--buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__column__icon {
    width: 15px;
    height: auto;
    margin: 0 10px;
}

.panelContent__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 40px;
}

.categoryList {
    margin-top: 50px;
}

.panelContent__filters__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
}

.panelContent__filters__label {
    margin-right: 20px;
    color: #fff;
}

.panelContent__input {
    padding: 5px 10px;
    border: none;
    outline: none;
    background: transparent;
}

.panelContent__input::-webkit-input-placeholder {
    color: #757575;
}

.panelContent__input:-ms-input-placeholder {
    color: #757575;
}

.panelContent__input::placeholder {
    color: #757575;
}

.panelContent__sortBtn {
    background: transparent;
    color: #757575;
    font-size: 14px;
    border: none;
    margin: 0 15px;
    cursor: pointer;
}

.panelContent__sortBtn--active {
    text-decoration: underline;
}

.panelContent__filters__label__label {
    color: #757575;
    font-size: 14px;
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addProduct__categorySelect .panelContent__filters__label__label {
    justify-content: flex-start;
}

.panelContent__filters__btn {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    margin-right: 7px;
    background: #fff;
    cursor: pointer;
    padding: 4px;
}

.d-none {
    display: none;
}

.panelContent__filters__btn--active {
    background: #0A0E2A;
    background: var(--main);
    display: block;
    width: 100%;
    height: 100%;
}

.panelContent__input__label {
    border: 1px solid #fff;
}

.panelContent__input__span {
    padding: 0 12px;
}

.panelContent__input__icon {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

/* Add product page */
.editor {
    background: #fff;
    border: 1px solid #000;
    padding: 0 10px;
    min-height: 300px;
}

.allergensImg {
    width: 40px;
    height: auto;
}

.editorWrapper {
    max-width: 600px;
}

.addProduct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--black-primary);
    padding: 50px;
}

.addProduct__header__h {
    text-align: left;
    font-size: 26px;
    margin-bottom: 20px;
    width: 100%;
}

.addProduct__header {
    width: 100%;
    margin: 40px 0;
    border-bottom: 1px solid #E8E8E8;
}

.admin__editorWrapper,
.addProduct__label {
    display: block;
    width: 100%;
    margin: 15px 0;
    font-size: 14px;
    color: #6B6B6B;
}

.fileInputLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.addProduct__form {
    width: 600px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__form__section {
    width: 100%;
    margin-top: -30px;
}

.addProduct__form--addProduct {
    display: flex;
    width: 80%;
    max-width: 1600px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.addProduct__input {
    padding: 5px 10px;
    width: 100%;
    height: 50px;
    opacity: 1;
    background: transparent;
    border: 1px solid #4653B1;
    border-radius: 3px;
    outline: none;
    margin-top: 4px;
}

.fileUploader {
    margin: 30px 0;
}

.addProduct__input::-webkit-input-placeholder {
    color: #757575;
}

.addProduct__input:-ms-input-placeholder {
    color: #757575;
}

.addProduct__input::placeholder {
    color: #757575;
}

.addProduct__categorySelect {
    width: 100%;
    font-size: 17px;
    padding-left: 10px;
    margin-top: 0;
}

.addProduct__form__section--bottom {
    padding: 20px;
    border: 1px solid #6B6B6B;
    border-radius: 5px;
    margin-left: 40px;
    margin-top: 0 !important;
}

.addProduct__btn {
    width: 100%;
    height: 60px;
    border-radius: 60px;
    border: none;
    outline: none;
    background: #0A0E2A;
    background: var(--main);
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    transition: .4s all ease-in-out;
}

.addProduct__btn:hover {
    background: #080D19;
    background: var(--hover);
}

.addProduct__label--file {
    width: 100%;
    height: 200px;
    border: 2px dashed #B4B4B4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8c8f94;
    text-transform: uppercase;
    cursor: pointer;
}

.addProduct__fileImg {
    width: 40px;
    height: auto;
    margin-bottom: 25px;
}

.addProduct__allergies {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto 50px;
}

.addProduct__allergies__item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    cursor: pointer;
    transition: .2s all ease-in-out;
    opacity: .3;
}

input[type=file] {
    display: block;
    margin: 18px auto;
    width: 200px;
}

.panelContent__productImg {
    width: 120px;
    height: auto;
}

.invisibleInput {
    visibility: hidden;
    height: 0;
}

.addProduct__form--addProduct > .addProduct__form__section > label:first-of-type {
    margin-top: 0;
}

.addProduct__form__subsection {
    display: flex;
    flex-direction: column;
}

.panelContent__filters__btn {
    margin-left: 20px;
}

.addProduct__categorySelect .panelContent__filters__btn {
    margin-left: 0;
}

.addProduct__categorySelect .panelContent__filters__label__label {
    margin-left: 0;
}

.panelContent__filters__btn--options {
    margin-right: 20px;
    margin-left: 0;
}

.panelContent__filters__btnWrapper {
    margin: 10px 0;
    cursor: pointer;
}

.marginTop30 {
    margin-top: 30px;
}

.addProduct__form__subsection--marginLeft {
    margin-left: 120px;
}

.addProduct__input--invisible {
    display: block;
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    transition: .4s all ease-in-out;
}

.jodit-container {
    margin-top: 15px;
    margin-bottom: 30px;
}

.jodit--label span {
    font-size: 14px;
}

.input--hidden {
    visibility: hidden !important;
}

.addMsg {
    margin-top: 100px;
}

/* Panel - categories */
.panelContent__contentWrapper,
.panelContent__frame {
    background: #fff;
    padding: 25px;
    margin-top: 50px;
    box-shadow: 0px 0px 10px #0A0E2A26;
    box-shadow: var(--shadow);
    border-radius: 10px;
}

.panelContent__frame__header {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.addedMsgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 40px;
}

.panelContent__frame__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px auto;
}

.addProduct__categorySelect {
    width: 100%;
    display: block;
}

.addProduct__label--frame {
    width: 100%;
    font-size: 13px;
    color: #8c8f94;
}

.panelContent__input__label--payment {
    margin: 10px 0;
    width: 100%;
}

.panelContent__input__label--payment .panelContent__input {
    width: 100%;
    height: 40px;
}

/* Panel - settings */
.panelContent__twoForms {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.addProduct__label--frame--margin {
    margin: 10px 0;
}

.marginTop10 {
    margin-top: 10px;
}

.formError--panel {
    color: red;
    font-size: 12px;
    text-transform: none;
    padding-top: 4px;
    display: block;
}

.formMsg {
    text-align: center;
    font-size: 20px;
    margin: 30px 0;
}

/* Modal on delete */
.panelModal .ReactModal__Overlay {
    background: rgba(0, 0, 0, .6) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelModal .ReactModal__Content {
    width: 500px !important;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 10px #0A0E2A26;
    box-shadow: var(--shadow);
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    border-radius: 10px !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.modalClose {
    background: transparent;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: auto;
    border: none;
    cursor: pointer;
}

.modalClose__img {
    width: 100%;
    height: auto;
}

.modalQuestion {
    font-size: 18px;
    text-align: center;
    padding: 20px;
}

.modalQuestion__btn {
    margin: 0 20px;
    background: #0A0E2A;
    background: var(--main);
    width: 150px;
    height: 50px;
    border-radius: 50px;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: .4s all ease-in-out;
}

.modalQuestion__btn:hover {
    background: #000;
    color: #fff;
}

/* Panel - payment */
.labelSpan {
    color: #8c8f94;
    font-size: 13px;
    display: block;
    margin-top: 15px;
    text-align: left;
    width: 100%;
}

.paymentMethods__msg {
    margin-top: 25px;
}

/* Order details */
.panelContent__header--smaller {
    font-size: 17px;
    font-weight: 400;
}

.panelContent__header--smaller b {
    font-size: 21px;
}

.panelContent__cart__content {
    margin: 40px 0 40px 20px;
    max-width: 1000px;
    position: relative;
}

.panelContent__cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}

.panelContent__cart__column:first-of-type {
    flex: 2 1;
}

.panelContent__cart__column {
    flex: 1 1;
}

.panelContent__cart__column:last-of-type {
    flex: 2 1;
}

.panelContent__cart__item span {
    font-size: 15px;
    font-weight: 700;
}

.panelContent__cart__sum {
    position: absolute;
    bottom: -100px;
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.panelContent__cart__summary {
    text-align: right;
    font-size: 18px;
    max-width: 1000px;
    margin-left: 20px;
}

.panelContent__cart__summary span {
    font-size: 15px;
    font-weight: 400;
}

.panelContent__cart__column {
    text-align: right;
}

.panelContent__orderDetails {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top: 120px;
}

.panelContent__clientData {
    width: 45%;
    margin-right: 50px;
}

.panelContent__shipping {
    width: 45%;
}

.panelContent__clientData__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
}

.panelContent__data {
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #000;
    padding: 0 20px;
    color: #757575;
    margin: 8px 0;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 48%;
}

.w-70 {
    width: 58%;
}

.w-30 {
    width: 38%;
}

.w-20 {
    width: 20%;
}

.panelContent__orderStatus__header {
    margin-top: 30px;
    font-size: 20px;
    justify-content: flex-start;
}

.panelContent__orderStatus__img {
    margin-left: 10px;
    width: 20px;
    height: auto;
}

.panelContent__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__cart__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panelContent__btn {
    margin: 0 20px;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    transition: .4s all ease-in-out;
}

.btn--red {
    border: 1px solid #941212;
    background: #FFBCBC;
    color: #941212;
    padding: 5px 10px;
}

.btn--red:hover {
    background: #941212;
    color: #fff;
}

.btn--neutral {
    background: #BCFFD9;
    color: #12944B;
    border: 1px solid #12944B;
    transition: .4s all ease-in-out;
}

.btn--neutral:hover {
    background: #12944B;
    color: #fff;
}

.button--link {
    background: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.panelContent__buttons--orderDetails .panelContent__btn {
    height: 35px;
    padding: 10px 15px;
}

.product__fileInput {
    margin-bottom: 50px !important;
}

.allergiesChecked {
    opacity: 1;
}

/* Start panel page */
.panelContent__startContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    height: 60vh;
}

.panelContent__start__item {
    border: 2px solid #F1F1F1;
    background: var(--black-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 29%;
    padding: 70px 30px;
    margin-bottom: 30px;
    opacity: .7;
    transition: .3s all;
}

.panelContent__start__item:hover {
    opacity: 1;
}

.panelContent__start__item__img {
    width: 90px;
    height: auto;
}

.panelContent__start__item__header {
    margin-top: 25px;
}

.settingsForm {
    width: 48%;
    max-width: 500px;
}

.panelContent__frame__main {
    margin-top: 30px;
}

.panelContent__ribbons__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    border: 1px solid black;
}

.panelContent__ribbons__column {
    font-weight: 400;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 0;
}

.panelContent__ribbons__column--caption {
    border-left: 1px solid #000;
    padding-left: 10px;
}

.panelContent__filters__label__label--category {
    margin-bottom: 20px;
    margin-top: 15px;
    width: 100%;
    justify-content: flex-start;
}

.panelContent__filters__label__label--category .panelContent__filters__btn {
    margin-left: 10px;
}

/* Blog */
.addProduct__form--addPost {
    flex-direction: column;
}

.addProduct__form--addPost, .panelContent__addPost,
.panelContent__addPost .jodit--label
{
    width: 100%;
}

.panelContent__addPost .jodit-workplace {
    min-height: 500px !important;
}

.addProduct__label--addPost {
    width: 100%;
    margin-bottom: 30px;
}

.en-division {
    display: block;
    margin: 40px 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, .25);
}

/* Others */
.panelContent--others {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.panelContent__othersSection {
    width: 45%;
}

.panelContent__ribbons__column__img {
    width: 20px;
}

.shippingForm {
    width: 100%;
}

.btn--maxWidth {
    max-width: 400px;
}

.d-flex .jodit--label {
    width: 45%;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.errorsContainer {
    color: red;
    font-size: 13px;
    margin-bottom: 10px;
}

.cart--flex--admin {
    flex-wrap: wrap;
}

.cart--flex--admin .addProduct__btn {
    max-width: 400px;
    margin: 20px auto;
}

.marginTop20 {
    margin-top: 20px;
    display: block;
}

.w-100 {
    width: 100%;
}

.smaller {
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}

.jodit, .jodit *, .jodit-container, .jodit-container * {
    color: #000 !important;
}

/* Modifications */
.d-flex > .addProduct__input {
    width: 47%;
}

.inPost__address__header {
    font-size: 17px;
    margin-bottom: 5px;
}

.inPost__address {
    color: #000;
    margin: 20px 0;
}

.panelContent__filters__btnWrapper:last-child .panelContent__filters__btn {
    margin-left: 15px;
}

.addShipping__input {
    max-width: 500px;
    margin: 10px 0;
}

.btn--editSectionHeader {
    max-width: 400px;
}

.pl-5 {
    padding-left: 25px;
}

.addProduct__input--textarea {
    height: 100px;
    margin-bottom: 0;
    resize: none;
}

.btn--shipping {
    margin-top: 10px;
}

/* Update - 20.08.21 */
.mainProductImage, .galleryProductImage {
   height: 150px;
    width: auto;
    margin: 15px;
    cursor: pointer;
}

.galleryProductImage--main {
    border: 3px solid #000;
}

.galleryWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

/* Stocks */
.addProduct--stock {
    align-items: flex-start;
}

.stockForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.chooseProducts {
    margin-left: 80px;
}

.panelContent--productList__item {
    color: #fff !important;
    margin-left: 0;
    justify-content: flex-start;
}

.panelContent--productList__item .panelContent__filters__btn {
    margin-left: 0;
}

.stockTitleLabel {
    width: 100%;
    margin: 30px auto;
    font-size: 14px;
}

.stockTitleInput {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border: 1px solid #fff;
    background: transparent;
    padding: 10px;
    outline: none;
}

.infoHeader--stock {
    width: 100%;
}

/* Update - 06.09.21 */
.panelContent__input--letterNumber {
    border: 1px solid #fff;
    margin: 0 15px;
}

.panelContent__select {
    margin: 0 15px;
}

.panelContent__editOrderBtn {
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    max-width: 300px;
}

/* Update - 20.09.21 */
.panelContent__column__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: -moz-max-content;
    margin: auto;
}

/* Update - 04.10.21 */
.panelContent__input--adminComment {
    color: #fff;
    resize: none;
    border: 1px solid #fff;
    display: inline-block;
    margin-left: 30px;
    margin-top: 20px;
}

.panelContent__header--smaller--adminComment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Update - 19.11.21 */
.panelContent .clientForm__subheader {
    text-align: left;
    margin: 20px 0 30px;
}

.panelContent .clientForm__label {
    justify-content: flex-start;
}

.panelContent .clientForm__label,
.panelContent .clientForm__row {
    width: clamp(100px, 600px, 100%);
}

.productList__item {
    align-items: center;
}

.productList__item label {
    margin-left: 50px;
    font-size: 13px;
    text-transform: uppercase;
}

.productList__item label select {
    font-size: 16px;
}

.productList__item label select {
    margin-left: 10px;
}

.productList__item__header {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 25px;
}

.productList__btn {
    width: 200px;
    height: 50px;
    background: #707070;
    color: #fff;
    text-transform: uppercase;
    margin-left: 30px;
}

.productList__icon {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
    width: 20px;
    margin: 0 20px;
}

.productList__productAdded {
    font-size: 15px;
    color: #fff;
}

.productList__productAddedWrapper {
    display: flex;
    align-items: center;
}

.panelContent--addOrder .shippingForm__products {
    background: #272727;
    padding: 40px;
    margin-top: 25px;
}

.panelContent__orderStatus__header {
    justify-content: flex-start !important;
    font-size: 15px;
}

.panelContent__orderStatus__header button {
    padding: 5px 20px;
    background: #0A0E2A;
    background: var(--main);
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    border: 1px solid #fff;
    margin-left: 25px;
    height: 35px;
    border-radius: 40px;
    cursor: pointer;
}

.panelContent--addOrder__header {
    text-align: center;
    margin-top: 20vh;
}

.loginPage {
    background: #F2F2F2;
    padding-top: 0 !important;
}

/* ------------------------------------------ */

:root {
    --shadow: 0px 0px 10px #0A0E2A26;
    --main: #0A0E2A;
    --hover: #080D19;
}

.loginPage__form {
    box-shadow: 0px 0px 10px #0A0E2A26;
    box-shadow: var(--shadow);
    border-radius: 10px;
    background: #fff;
}

.button--loginForm {
    background: #0A0E2A;
    height: 50px;
    border-radius: 50px;
}

.input--adminLogin {
    height: 50px;
    border-radius: 50px;
    color: #000;
    padding-left: 20px;
}

.loginPage__form .label {
    color: #000;
    font-style: italic;
    text-align: center;
}

.panelContent__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.panelContent__btn {
    color: #fff;
    background: #0A0E2A;
    background: var(--main);
    padding: 10px 30px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all;
}

.panelContent__btn:hover {
    background: #080D19;
    background: var(--hover);
}

.panelContent__btn__icon {
    width: 20px;
    margin-left: 15px;
}

.response {
    position: absolute;
    top: 30%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
}

.response--positive {
    background: green;
}

.response--negative {
    background: #941212;
}

.admin__addGallery {
    color: #6B6B6B;
    font-size: 14px;
}

.addProduct__categorySelect {
    color: #6B6B6B;
    font-size: 14px;
    padding: 20px;
    border: 1px solid #6B6B6B;
    border-radius: 5px;
}

.addProduct__categorySelect .panelContent__filters__label__label:first-of-type {
    margin-top: 25px;
}

.admin__label__imgUpload__trashBtn {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .7);
    z-index: 99999;
}

.admin__label__imgUpload__trashBtn .btn__img {
    width: 40%;
}

.admin__label__imgUpload::after {
    content: "+";
    font-size: 120px;
    text-align: center;
    color: #6B6B6B;
    position: absolute;
    top: 65%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.admin__label__imgUpload__updateImgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 5;
}

.admin__label__imgUpload__updateImg {
    height: 190px;
    width: auto;
    max-width: 100%;
}

.admin__label__imgUpload {
    width: 300px;
    height: 200px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px dashed #6B6B6B;
    margin-top: 10px;
    position: relative;
}

.admin__label {
    font-size: 14px;
    color: #6B6B6B;
}

.dzu-inputLabel {
    opacity: 0;
}

.admin__label--imgUpload {
    cursor: pointer;
    margin-bottom: 30px;
}

.admin__flex .dzu-previewImage {
    max-width: 290px;
    max-height: 190px;
    margin: 0;
}

.dzu-previewStatusContainer {
    display: none !important;
}

.admin__label--imgUpload--section {
    width: 60%;
}

.alignTop {
    align-items: flex-start !important;
}

.rug .rug-items.__card {
    min-height: 40px !important;
}

.addProduct__bottom {
    margin-top: 40px;
}

.panelContent__column--img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
}

.mt-4 {
    margin-top: 20px;
}

.center {
    justify-content: center !important;
}

.center .admin__label--imgUpload--section {
    width: auto;
    margin: 0 20px;
}

.panelContent__editOrderBtn {
    background: #2B113E;
    color: #fff;
    height: 40px;
    border-radius: 40px;
    cursor: pointer;
}

.infoHeader {
    margin-top: 12px;
    font-weight: 400;
    font-size: 15px;
}

.panelStart__btn {
    background: #F2F2F2;
    width: 175px;
    height: 35px;
    border-radius: 35px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelContent__frame--orders {
    width: 60%;
}

.panelContent__frame--stats {
    width: 37%;
}

.panelContent__startContent {
    align-items: flex-start;
    margin-top: 0;
}

.panelContent__startContent .panelContent__column__key,
.panelContent__startContent .panelContent__column__value {
    font-size: 12px;
    margin: 0 !important;
}

.panelContent__startContent .orderItem {
    padding: 5px 0;
}

.panelContent__startContent .panelContent__column__status {
    font-size: 10px;
    height: 25px;
    margin-top: 5px;
}

.panelContent__startContent .panelContent__column__label {
    margin-bottom: 5px;
}

.panelStart__subheader {
    margin: 10px 0;
}

.panelStart__stats {
    max-width: 300px;
}

.panelStart__stats > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.panelStart__header--big {
    font-size: 28px;
}

.panelContent__frame--quick {
    margin-top: 20px;
    height: 140px;
    margin-bottom: 140px;
}

.quickBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quickBtn .icon {
    width: 25px;
    margin: 0 0 10px 0;
    -webkit-filter: brightness(0);
            filter: brightness(0);
}

.mt {
    margin-top: 20px !important;
}

.panelContent--customFields .addProduct__input {
    margin-bottom: 30px;
}

.twoImages {
    justify-content: space-evenly !important;
}


.videoUploaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
    transition: .4s all;
}

.videoUploader {
    background: #fff;
    border-radius: 30px;
    width: clamp(200px, 80vw, 1200px);
    height: 700px;
    max-height: 80vh;
    position: relative;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videoLoadingWrapper {
    margin-top: 7%;
}

.videoLoadingWrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressBar {
    width: 400px;
    height: 10px;
    border-radius: 10px;
    display: block;
    margin: 60px auto 0;
    background: var(--dark);
    position: relative;
    overflow: hidden;
}

.progressBar__inner {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s all;
    background: var(--gold);
}

.progressBar__percent {
    font-size: 20px;
    color: #313131;
    font-weight: 700;
    font-family: 'Oxanium', cursive;
    text-align: center;
    display: block;
    margin-top: 60px;
}

.player__header--videoUploader {
    position: absolute;
    top: 50px;
    left: 80px;
}

.videoUploader__imgWrapper {
    background: transparent;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoUploader__img {
    width: 50%;
}

.videoUploader__text {
    text-align: center;
    color: #3d3d3d;
    margin: 20px 0;
    font-size: 28px;
    max-width: 400px;
}

.videoUploader__smallText {
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    max-width: 500px;
}

.button--videoUploader {
    width: 400px;
    max-width: 80%;
}

.videoUploader .dzu-dropzone {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    opacity: 0;
    cursor: pointer;
}

.dzu-inputLabel {
    cursor: pointer;
}

.videoUploader .dzu-dropzone::after {
    display: none;
}

.videoUploader__responseHeader {
    color: #3d3d3d;
    text-align: center;
    margin-top: 30px;
}

.registerModal__closeBtn {
    background: transparent;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    outline: none;
    z-index: 100;
    width: 30px;
    cursor: pointer;
}

.registerModal__closeBtn__img {
    width: 100%;
    height: auto;
}

.dzu-inputLabel {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 11;
    opacity: 0;
}

.button--videoUploader {
    padding: 10px 20px;
    height: 50px;
    border-radius: 50px;
    background: #0A0E2A;
    background: var(--main);
    color: #fff;
    font-weight: 700;
}

.panelContent--customFields .panelContent__frame {
    position: relative;
    z-index: 10;
}

.addVideoBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border-radius: 40px;
    color: #fff;
    background: #0A0E2A;
    background: var(--main);
    cursor: pointer;
    border: none;
    outline: none;
}

.videoTable__item__miniature {
    max-width: 80%;
}

.mediaType {
    margin-top: 30px;
}

.mediaType label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.mediaType__btn {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px solid #0A0E2A;
    border: 1px solid var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.mediaType__btn span {
    display: none;
}

.mediaType__btn--selected span {
    background: #0A0E2A;
    background: var(--main);
    display: block;
    width: 15px;
    height: 15px;
}

.align-top > .admin__label--imgUpload {
    width: auto;
}

.align-top {
    margin: 10px 0 40px;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin--grey {
    font-size: 14px;
    color: #6B6B6B;
}

.align-top > * {
    margin-right: 10%;
    margin-top: 4px;
}

.admin--grey .video-react {
    overflow: hidden;
}

.align-top .videoTable__item__miniature {
    width: 100%;
    max-width: 100%;
}

@media(max-width: 1200px) {
    /* Admin */
    .panelMenu__top__logoWrapper {
        display: none;
    }

    .panelContent__item {
        flex-wrap: wrap;
    }

    .orderItem .panelContent__column {
        flex-basis: 30%;
        margin-bottom: 30px;
    }

    .panelContent__column__label {
        margin-bottom: 7px;
    }

    .addProduct__form--addProduct {
        flex-wrap: wrap;
    }

    .addProduct__form--addProduct > * {
        width: 100% !important;
    }

    .addProduct__form__subsection--marginLeft {
        margin-left: 0;
    }
}

@media(max-width: 996px) {
    /* Admin */
    .panel {
        display: block;
    }

    .panelMenu {
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: #000;
        padding: 10px 5%;
    }

    .panelMenu__menuMobile {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .panelContent {
        width: 100%;
        padding: 20px 5%;
        margin-left: 0;
        margin-top: 100px;
    }

    .panelMenu__menuMobile__hamburgerWrapper {
        background: transparent;
    }

    .panelMenu__menuMobile__hamburgerImg {
        width: 30px;
        height: auto;
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
    }

    .panelMenu__top__header,
    .panelMenu__top__subheader {
        color: #fff;
    }

    .panelContent__header__back,
    .panelContent__header__back:hover .panelContent__header__back__img
    {
        -webkit-transform: none;
                transform: none;
    }

    .panelContent__cart__column {
        font-size: 14px;
    }

    .panelContent__cart__item {
        padding-bottom: 15px;
    }

    .panelContent__cart__item:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, .25);
    }

    .panelMenu__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: 11;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .panelMenu__list {
        width: 100%;
    }

    .panelMenu__menuMobile__closeBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        background: transparent;
    }

    .panelMenu__menuMobile__closeBtn__img {
        width: 100%;
        height: auto;
    }

    .addProduct__header, .addProduct__header__h {
        margin: 15px 0;
        font-size: 18px;
    }

    .panelContent__ribbons__column {
        font-size: 13px;
    }
}

@media(max-width: 768px) {
    /* Admin */
    .orderItem .panelContent__column {
        flex-basis: 49%;
    }

    .panelContent__column__value,
    .panelContent__column__label {
        font-size: 12px !important;
    }

    .panelContent__filters__label,
    .panelContent__filters__label__label,
    .panelContent__sortBtn
    {
        font-size: 13px;
    }

    .panelContent__filters__label__label {
        margin: 0;
    }

    .panelContent__filters__label {
        margin-right: 5px;
    }

    .panelContent__input__label {
        width: 180px;
    }

    .panelContent__input {
        width: calc(100% - 20px);
    }

    .panelContent__filters__btn {
        margin-left: 10px;
    }

    .productItem .panelContent__column {
        flex-basis: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .panelContent__frame__form {
        width: 100%;
        margin: 0;
    }

    .panelContent__frame__header {
        font-size: 17px;
    }

    .categoriesForm .addProduct__label {
        margin: 10px 0;
    }

    .paymentContent .labelSpan {
        text-align: center;
    }

    .panelContent__input__label--payment {
        width: 100%;
    }

    .addProduct__btn {
        padding: 10px;
    }

    .panelContent__twoForms {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .settingsForm {
        width: 100%;
        margin: 40px 0;
    }

    .panelContent__cart__item {
        flex-wrap: wrap;
    }

    .panelContent__cart__column {
        flex-basis: 30%;
        text-align: left;
        white-space: nowrap;
    }

    .panelContent__data {
        width: 100% !important;
        padding: 12px;
        font-size: 13px;
    }

    .panelContent__cart__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__cart__header > *:last-child {
        margin-bottom: 20px;
    }

    .panelContent__cart__header .btn--neutral {
        margin-left: 0;
    }

    .panelContent__cart__column {
        border: 1px solid rgba(0, 0, 0, .25);
        padding: 10px !important;
    }

    .panelContent__cart__column span {
        font-weight: 400;
        font-size: 13px;
    }

    .panelContent__header--smaller {
        order: 2;
    }

    .panelContent__buttons {
        order: 1;
    }

    .addProduct__form--addProduct {
        width: 100%;
    }

    .panelContent__ribbons__item {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__ribbons__column--caption {
        border-left: none;
        padding-left: 0;
        border-top: 1px solid #000;
    }

    .d-mobile {
        display: block !important;
    }

    .d-desktop {
        display: none !important;
    }
}

@media(max-width: 576px) {
    /* Admin */
    .panelContent__start__item {
        width: 48%;
    }

    .panelContent__header__h {
        font-size: 17px;
    }

    .panelContent__header__back {
        font-size: 13px;
    }

    .panelContent__header__back__img {
        width: 18px;
        margin-left: 16px;
    }

    .panelModal .ReactModal__Content {
        width: 100% !important;
        height: 100vh !important;
    }
}

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}

html {
    scroll-behavior: smooth;
}

:root {
    --main: #361D48;
    --hover: #250C37;
    --text: #342A51;
}

.w {
    width: clamp(200px, 90vw, 1600px);
    max-width: 90vw;
    margin: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trans {
    background: transparent;
    border: none;
    outline: none;
}

.btn__img {
    width: 100%;
    height: auto;
}

.container {
    width: clamp(200px, 90vw, 1600px);
    max-width: 90vw;
    margin: auto;
}

.container .siteHeader {
    padding-top: 30px;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #fff;
    z-index: 5;
    transition: .3s all;
}

.siteHeader__logoWrapper {
    display: block;
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 150px;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    transition: .3s all;
}

.siteHeader__right {
    width: 240px;
    height: 50px;
}

.siteHeader__right__item {
    width: 130px;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
}

.siteHeader__right__item .btn__img {
    width: auto;
    height: 30px;
    margin-bottom: 5px;
    transition: .2s all;
}

.siteHeader__right__item:hover .btn__img {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.topMenu {
    border-bottom: 1px solid #CBCBCB;
    padding: 40px 5% 7px;
    margin-top: 170px;
}

.topMenu__list {
    list-style-type: none;
    margin: auto;
    width: 90%;
}

.topMenu__list__link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: #361D48;
    color: var(--main);
    transition: .2s all;
}

.topMenu__list__link:hover {
    color: inherit;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.slider {
    margin: 30px auto;
}

.row__header {
    margin: 30px auto;
    text-align: center;
    color: #361D48;
    color: var(--main);
    font-size: 32px;
    text-transform: uppercase;
}

.preview {
    width: 100%;
}

.preview__title {
    font-size: 18px;
    margin: 10px 0;
    color: #361D48;
    color: var(--main);
}

.preview__subtitle {
    font-weight: 400;
    font-size: 14px;
}

.preview__price {
    margin: 15px 0;
    color: #361D48;
    color: var(--main);
    font-size: 24px;
}

.addToCartBtn {
    background: #361D48;
    background: var(--main);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    height: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: .2s all;
}

.addToCartBtn:hover {
    background: #250C37;
    background: var(--hover);
}

.addToCartBtn__img {
    width: 12px;
    margin-left: 10px;
}

.preview__imgWrapper {
    width: 350px;
    max-width: 100%;
    height: 390px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.preview__imgWrapper .btn__img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: .4s all;
    height: 100%;
    width: auto;
    min-width: 100%;
}

.previewImg2 {
    opacity: 0;
}

.preview__imgWrapper:hover .previewImg1 {
    opacity: 0;
}

.preview__imgWrapper:hover .previewImg2 {
    opacity: 1;
}

.infoSection {
    background: #EBE8ED;
    margin: 60px auto;
}

.infoSection--2 {
    height: 500px;
}

.infoSection--3 {
    height: 485px;
}

.infoSection__imgWrapper {
    width: 600px;
    height: 650px;
}

.infoSection__imgWrapper--2 {
    width: 825px;
    height: 500px;
}

.infoSection__imgWrapper--3 {
    width: 800px;
    height: 485px;
}

.infoSection__content__main {
    height: auto;
}

.infoSection__content__main h1,
.infoSection__content__main h2,
.infoSection__content__main h3,
.infoSection__content__main h4 {
    color: #361D48 !important;
    color: var(--main) !important;
}

.infoSection__content {
    width: calc(100% - 600px);
    padding-left: 30px;
    padding-right: 30px;
}

.infoSection--2 .infoSection__content {
    width: calc(100% - 825px);
}

.infoSection--3 .infoSection__content {
    width: calc(100% - 800px);
}

.infoSection--2 .infoSection__content__main {
    height: auto;
}

.infoSection--3 .infoSection__content__main {
    height: auto;
}

.infoSection__btn {
    background: #361D48;
    background: var(--main);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.infoSection__btn--100 {
    height: 60px;
}

.arrowIcon {
    width: 15px;
    margin-left: 20px;
    transition: .2s all;
}

.infoSection__btn:hover .arrowIcon {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
}

.row--popular {
    margin-top: 100px;
}

.infoSection--2 {
    margin-bottom: 25px;
}

.aboutUs {
    margin-top: 60px;
    padding-top: 5px;
    border-top: 1px solid #E3E3E3;
}

.merits__item {
    flex-direction: column;
    width: 15%;
}

.merits__icon {
    width: 50px;
}

.merits__header {
    margin: 20px auto;
    font-size: 16px;
    text-align: center;
}

.merits__text {
    text-align: center;
    font-size: 12px;
}

.merits__text, .merits__header {
    color: #361D48;
    color: var(--main);
}

.newsletter {
    border-top: 1px solid #E3E3E3;
    margin: 50px auto 30px;
    padding: 25px 0 0;
    transition: .3s all;
}

.newsletter__form {
    position: relative;
    justify-content: flex-end;
}

.newsletter__header {
    font-size: 36px;
    color: #361D48;
    color: var(--main);
    padding-right: 60px;
    border-right: 1px solid #E3E3E3;
}

.d-block {
    display: block;
}

@media(min-width: 1800px) {
    .newsletter__text {
        margin-left: -10%;
    }
}

.newsletter__text {
    margin-left: -8%;
    color: #361D48;
    color: var(--main);
}

.newsletter__form label {
    width: auto;
}

.newsletter__input {
    width: 500px;
    height: 50px;
    padding: 5px 15px;
    border: 2px solid #000;
}

.newsletter__submitBtn {
    background: #361D48;
    background: var(--main);
    width: 175px;
    height: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    margin-left: 15px;
    cursor: pointer;
}

.newsletter__submitBtn:hover .arrowIcon {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
}

.newsletter__success {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    z-index: -1;
    transition: .3s opacity;
    font-size: 14px;
    text-align: center;
}

label {
    position: relative;
}

.error {
    max-width: 100%;
    position: absolute;
    bottom: -30px;
    font-size: 13px;
    color: red;
    left: 0;
    font-weight: 400;
    width: 500px;
}

.footer {
    border-top: 1px solid #E3E3E3;
    padding-top: 30px;
}

.footer__bottom {
    border-top: 1px solid #E3E3E3;
    padding: 15px 0;
    flex-direction: column;
}

.footer__bottom__header {
    color: #6B6B6B;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.footer__header {
    font-size: 14px;
    margin-bottom: 7px;
    color: #361D48;
    color: var(--main);
}

.footer__text {
    color: #6B6B6B;
    font-size: 14px;
    display: block;
    margin: 3px 0;
}

.footer__text a:hover {
    opacity: .8;
}

.footer__main {
    padding-bottom: 25px;
    align-items: flex-start;
}

.footer__col__content {
    margin-left: 45px;
}

.footer__text--big {
    font-weight: 700;
    font-size: 26px;
    margin: 3px 0 8px;
}

.page {
    max-width: 95%;
    margin: 40px auto;
    overflow: hidden;
}

.page__header {
    color: #342A51;
    color: var(--text);
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
}

.form__header {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 3px;
    border-bottom: 1px solid #F2F2F2;
    color: #6B6B6B;
}

.form {
    max-width: 500px;
    margin: 0 auto 60px;
}

html {
    scroll-behavior: smooth;
}

label {
    width: 100%;
    margin: 4px 0;
    display: block;
}

.input {
    transition: .2s all;
    width: 100%;
    border: 2px solid #361D48;
    border: 2px solid var(--main);
    padding: 5px 12px;
    height: 50px;
}

.form__header--2 {
    margin-top: 20px;
}

.form__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.noProductsWrapper .btn--back {
    margin-left: 0;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #361D48;
    background: var(--main);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
}

.label--name {
    width: 40%;
}

.label--surname {
    width: 57%;
}

.label--postalCode {
    width: 35%;
}

.label--city {
    width: 63%;
}

.label--street {
    width: 50%;
}

.label--flat,
.label--building {
    width: 23%;
}

.icon {
    width: 15px;
    margin-left: 15px;
}

.btn--submit {
    width: 100%;
    height: 50px;
    margin-top: 7px;
    margin-bottom: 90px;
}

.btn .icon {
    transition: .2s all;
}

.btn:hover .icon {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
}

.input::-webkit-input-placeholder {
    font-size: 12px;
}

.input:-ms-input-placeholder {
    font-size: 12px;
}

.input::placeholder {
    font-size: 12px;
}

.page--ty {
    margin: 100px auto;
}

.ty__header, .ty__subheader {
    text-align: center;
    font-size: 24px;
    max-width: 700px;
    margin: auto;
}

.ty__subheader {
    font-size: 17px;
    font-weight: 400;
    margin-top: 20px;
}

.btn--back {
    margin: 30px auto;
    width: 300px;
    max-width: 100%;
    height: 50px;
    transition: .2s all;
}

.btn--back:hover {
    background: #250C37;
    background: var(--hover);
}

.input--error {
    border-color: red;
}

.label--check {
    color: #6B6B6B;
    font-size: 12px;
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.checkBtn {
    width: 15px;
    height: 15px;
    background: transparent;
    border: 2px solid #361D48;
    border: 2px solid var(--main);
    margin-right: 10px;
    position: relative;
}

.checkBtn img {
    opacity: 0;
    width: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.checkBtn--checked img {
    opacity: 1;
}

.afterLogin {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 13px;
    background: transparent;
    border: none;
    outline: none;
    width: 500px;
    margin: auto;
    display: block;
    text-align: left;
}

button.afterLogin,
.afterLogin a {
    text-decoration: underline;
    cursor: pointer;
    transition: .2s all;
}

button.afterLogin {
    margin-bottom: 4px;
}

button.afterLogin:hover,
.afterLogin a:hover {
    opacity: .8;
}

.page--login .form,
.page--login .btn--submit {
    margin-bottom: 12px;
}

h4.afterLogin {
    margin-bottom: 100px;
}

.page--login .error,
.page--register .error {
    width: 500px;
    margin: auto;
    max-width: 100%;
    position: relative;
    bottom: 0;
    margin-top: -70px;
    text-align: center;
}

.page--login .error {
    margin-top: 20px;
    margin-bottom: 30px;
}

.menu,
.cart {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    max-width: 700px;
    height: 100vh;
    background: #fff;
    z-index: 100;
    padding: 40px 30px;
    transition: .2s all;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
}

.background {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: .2s opacity;
}

.siteHeader__menuBtn {
    cursor: pointer;
}

.menu > *,
.cart > * {
    opacity: 0;
    transition: .2s all;
}

.cart {
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.panelContent::-webkit-scrollbar,
.menu__search--mobile::-webkit-scrollbar,
.panelMenu__list::-webkit-scrollbar,
.menu__main::-webkit-scrollbar,
.cart::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.panelContent::-webkit-scrollbar,
.menu__main,
.panelMenu__list,
.menu__search--mobile,
.cart {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.cart__item__attribute {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 13px;
    display: block;
}

.menu {
    right: unset;
    left: 0;
    width: 1250px;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    max-width: 95vw;
}

.cart__header {
    font-size: 32px;
    color: #361D48;
    color: var(--main);
    padding-bottom: 12px;
    border-bottom: 1px solid #CBCBCB;
}

.cart__close {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 12px;
    background: transparent;
    border: none;
    outline: none;
    -webkit-filter: invert(.5);
            filter: invert(.5);
    cursor: pointer;
}

.cart__sum h4 {
    color: #6B6B6B;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #CBCBCB;
}

.menu__search--mobile .noProducts {
    text-align: center;
    padding-top: 30px;
}

.cart__sum h5 {
    font-size: 16px;
    font-weight: 400;
}

.btn--cart {
    width: 100%;
    height: 50px;
}

.cart__item {
    margin: 15px 0;
    padding: 15px 0;
    border-bottom: 1px solid #E3E3E3;
}

.cart__item__count {
    border: 1px solid #361D48;
    border: 1px solid var(--main);
    padding: 4px 6px;
    font-size: 14px;
    text-align: center;
    margin: 0 9px;
    font-weight: 400;
    min-width: 25px;
}

.cart__item__imgWrapper {
    width: 120px;
    max-height: 220px;
    overflow: hidden;
}

.cart__item__img {
    width: 100%;
    height: auto;
    border: 2px solid #361D48;
    border: 2px solid var(--main);
}

.cart__item__title {
    font-size: 13px;
    font-weight: 500;
    margin-left: 20px;
    max-width: 250px;
}

.cart__item__firstCol {
    max-width: 220px;
    justify-content: flex-start;
}

.cart__item__btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    transition: .2s all;
}

.cart__item__btn:hover {
    opacity: .8;
}

.cart__item__price {
    font-weight: 400;
    font-size: 16px;
}

.page--beforeCheckout > main {
    margin-top: 5%;
    align-items: flex-start;
    justify-content: center;
}

.page--beforeCheckout > main > div {
    width: 44%;
}

.page--beforeCheckout > main > div:first-of-type {
    padding-right: 5%;
}

.page--beforeCheckout > main > div:last-of-type {
    padding-left: 5%;
}

.beforeCheckoutDivider {
    width: 1px;
    height: 300px;
    background: #CBCBCB;
    display: block;
}

.page--beforeCheckout > main ul,
.page--beforeCheckout > main li,
.page--beforeCheckout > main p {
    color: #6B6B6B;
    font-size: 14px;
    margin: 10px 0;
    max-width: 500px;
}

.page--beforeCheckout > main li {
    margin: 3px 0;
}

.page__header--left {
    text-align: left;
}

.page--beforeCheckout > main ul {
    list-style-type: disc;
    margin-left: 20px;
}

.page--beforeCheckout > main p a {
    text-decoration: underline;
}

.btn--continue {
    max-width: 500px;
    margin-top: 20px;
}

.page--beforeCheckout__backWrapper {
    position: absolute;
    top: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: clamp(200px, 90vw, 1600px);
}

.page--beforeCheckout__back {
    text-decoration: underline;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: -moz-max-content;
    opacity: .75;
}

.page--beforeCheckout__back:hover {
    opacity: 1;
}

.page--beforeCheckout__back img {
    margin: 0 8px 0 0;
    width: 11px;
}

.menu {
    padding: 35px 6%;
}

.cart__close--menu {
    width: 20px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
}

.menu label {
    width: 300px;
}

.searchInput {
    width: 100%;
    height: 35px;
    border: 2px solid #361D48;
    border: 2px solid var(--main);
    padding: 5px 10px;
}

.menu .page__header {
    text-align: left;
    margin-top: 20px;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
}

.menu__main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    height: 80%;
    padding-left: 20px;
}

.menu__main a {
    font-size: 15px;
    transition: .1s all;
}

.menu__main a:hover {
    opacity: .7;
    -webkit-transform: translateX(2px);
            transform: translateX(2px);
}

.menu__main__categoryBlock {
    margin-bottom: 20px;
    margin-right: 40px;
}

.menu__main__category {
    font-weight: 700;
    position: relative;
}

.menu__main__category::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    width: 15px;
    height: 2px;
    background: #E3E3E3;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.menu__main__categoryBlock > ul {
    list-style-type: disc;
    margin-left: 20px;
}

.menu__main__subcategory {
    display: block;
    margin: 5px 0;
}

.products {
    align-items: flex-start;
    justify-content: flex-start;
}

.products > * {
    margin-right: 60px;
}

.cartPage__main {
    margin-top: 30px;
}

.cart__sum--cartPage {
    justify-content: flex-end;
}

.cart__sum--cartPage h4 {
    margin-right: 30px;
}

.cartPage__main .cart__item__firstCol {
    max-width: none;
}

.cartPage__main .cart__item__imgWrapper {
    width: 150px;
}

.cartCol__key, .cartCol__value {
    text-align: center;
    font-weight: 400;
}

.cartCol__key {
    font-size: 12px;
    color: #6B6B6B
}

.cartCol__value {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}

.cartCol__btn {
    background: transparent;
    width: 16px;
    cursor: pointer;
    opacity: .7;
    border: none;
    outline: none;
    transition: .2s all;
}

.cartCol__btn:hover {
    opacity: 1;
}

.cartPage__main .emptyCart__header {
    text-align: center;
}

.emptyCart--right {
    margin-top: 30px;
}

.container--deliveryData .cart {
    box-shadow: -3px 0px 5px #0000000D;
}

.deliveryData__header {
    font-size: 32px;
    margin: 30px 0;
}

.form--deliveryData {
    margin-left: 0;
}

.deliveryData__bottom > * {
    position: relative;
    left: 0;
    top: 0;
}

.deliveryData__bottom .page--beforeCheckout__back:first-of-type {
    margin-bottom: 10px;
}

.container--deliveryData .btn--cart,
.container--deliveryData .cart__close {
    display: none;
}

.container--deliveryData .cart__sum h4 {
    border-bottom: none;
    padding-top: 0;
}

.container--deliveryData .cart__sumWrapper {
    border-bottom: 1px solid #E3E3E3;
}

.container--sAndP .deliveryData {
    max-width: 53%;
}

.container--sAndP .deliveryData__bottom * {
    text-align: right;
}

.container--sAndP .page--beforeCheckout__back {
    justify-content: flex-end;
}

.btn--payment {
    display: none;
}

.container--sAndP .btn--payment {
    display: flex;
    margin-top: 20px;
}

.container--sAndP .cart__sum:not(.cart__sum--noBorder) {
    border-top: 1px solid #E3E3E3;
    padding-top: 10px;
}

.cart__sum--noBorder {
    display: none;
    border: none;
}

.container--sAndP .cart__sum--noBorder {
    display: flex;
}

.delivery__desc {
    color: #6B6B6B;
    font-size: 12px;
    margin-top: 5px;
}

.delivery__title {
    font-weight: 400;
    font-size: 17px;
}

.delivery__price {
    font-size: 16px;
}

.sAndP__main .label--check {
    align-items: flex-start;
    justify-content: space-between;
}

.label__deliveryBlock {
    display: block;
    margin-top: -5px;
}

.label--check--delivery {
    padding-top: 10px;
    flex-wrap: wrap;
}

.sAndP__main {
    max-width: 600px;
}

.sAndP__main .label--check .label__deliveryBlock {
    width: calc(100% - 30px);
}

.form__header--mt {
    margin-top: 50px;
}

.bigModal {
    position: relative;
    z-index: 10000;
}

.hidden {
    visibility: hidden;
    height: 0;
    margin: 0;
}

.myAccount__header {
    font-size: 18px;
    color: #361D48;
    color: var(--main);
    margin-bottom: 20px;
}

.myAccount__section {
    width: 35%;
    margin-bottom: 50px;
}

.myAccount__main {
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
}

.btn--myAccount {
    margin-top: 10px;
    font-size: 15px;
}

.single__gallery {
    width: 40%;
}

.single__mainInfo {
    width: 57%;
}

.single__header {
    padding-bottom: 5px;
    border-bottom: 1px solid #E3E3E3;
}

.single__header * {
    color: #361D48;
    color: var(--main);
}

.single__title {
    font-size: 32px;
    font-weight: 600;
}

.single__price {
    font-size: 36px;
    font-weight: 700;
}

.single__desc {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.7em;
}

.single__icons__item {
    width: 25px;
    margin-right: 10px;
}

.smallHeader {
    color: #6B6B6B;
    font-size: 12px;
    font-weight: 400;
    margin-right: 25px;
}

.single__row {
    margin-top: 20px;
    justify-content: flex-start;
}

.single__icons {
    margin-right: 50px;
}

.addToCartBtn--single {
    margin-top: 20px;
}

.singleTop, .singleMiddle, .singleBottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}

.inPostAddress {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.locationIcon {
    width: 30px;
    margin-right: 15px;
}

.codeForm {
    margin-bottom: 40px;
    margin-top: 10px;
}

.codeForm label {
    width: 70%;
    max-width: 300px;
    height: 40px;
    margin-right: 20px;
}

.input--code {
    height: 40px;
    margin-top: 0;
}

.codeForm {
    justify-content: flex-start !important;
}

.btn--code {
    width: 28%;
    min-width: 200px;
    height: 40px;
    transition: .2s all;
}

.btn--code:hover {
    background: #250C37;
    background: var(--hover);
}

.codeForm .icon {
    margin-left: 0 !important;
}

.codeHeader {
    font-weight: 400;
    font-size: 15px;
    margin-left: 10px;
}

.galleryModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .4s opacity;
}

.closeModalBtn {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-filter: brightness(0) invert(.8);
            filter: brightness(0) invert(.8);
}

.modalImgWrapper {
    max-width: 80%;
    max-height: 80vh;
    transition: .3s all;
}

.modal__arrow {
    width: 25px;
    height: auto;
    background: transparent;
    border: none;
    outline: none;
    opacity: .75;
    transition: .2s all;
}

.modal__arrow:hover {
    opacity: 1;
}

.modal__arrow--next {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.single__gallery__bottom__item,
.single__gallery__main {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
}

.single__gallery__bottom,
.single__top {
    align-items: flex-start !important;
}

.single__gallery__bottom__item {
    width: 30%;
    height: 170px;
    overflow: hidden;
}

.single__gallery__bottom {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #E3E3E3;
}

.single__gallery__bottom__item .btn__img {
    height: 100%;
    width: auto;
}

.singleBottom__section,
.singleMiddle {
    margin: 30px auto;
    padding: 20px 5%;
    border-top: 1px solid #E3E3E3;
    justify-content: center !important;
}

.singleMiddle > * {
    width: 50%;
}

.singleMiddle__imgWrapper {
    margin-right: 4%;
}

.page--single button {
    cursor: pointer;
}

.modal__arrow {
    cursor: pointer;
}

.modal__arrow--prev {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.modal__arrow--next {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.infoSection ul,
.singleMiddle ul, .singleBottom ul {
    list-style-type: disc;
    margin-left: 20px;
}

.singleBottom__section {
    border: none;
}

.singleBottom__section .single__desc {
    width: 60%;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }

    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }

    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
}

.loader {
    width: 100%;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader__img {
    width: 100px;
    height: auto;
    -webkit-animation: pulse 1.5s infinite;
            animation: pulse 1.5s infinite;
}

.container--single .row--popular {
    padding-bottom: 50px;
}

.single__icons--attributeSection {
    justify-content: flex-start;
    margin-top: 15px;
}

.products,
.row > main.flex {
    display: grid;
    grid-template-columns: repeat(4, 22%);
    grid-row-gap: 40px;
    grid-column-gap: 4%;
}

.noProductsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.noProducts {
    font-weight: 400;
    width: 100%;
}

.myAccount__section .noProducts {
    margin-bottom: 15px;
}

.singleOrder {
    padding: 10px 0;
    border-bottom: 1px solid #E3E3E3;
}

.singleOrder__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.singleOrder__key {
    color: #6B6B6B;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 6px;
}

.singleOrder__value {
    font-size: 14px;
    font-weight: 500;
}

.singleOrder__btn {
    background: transparent;
    border: none;
    outline: none;
    width: 15px;
    display: block;
    opacity: .78;
    transition: .2s all;
}

.singleOrder__btn:hover {
    opacity: 1;
}

.container--orderHistory > main {
    margin: 50px auto;
}

.container--orderHistory .singleOrder {
    max-width: 700px;
    margin: auto;
}

.opacity-0 {
    opacity: 0;
}

.siteHeader__menuBtn, .siteHeader__right {
    transition: .2s all;
}

.infoSection .btn__img {
    min-height: 100%;
}

.banner {
    max-height: 500px;
    overflow: hidden;
}

.banner img {
    transition: .25s all;
}

.banner:hover img {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-filter: brightness(.9);
            filter: brightness(.9);
}

.afterSlider__imgWrapper:hover .btn__img {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.afterSlider__imgWrapper .btn__img {
    transition: .2s all;
}

.banner__img {
    width: 100%;
    height: auto;
}

.d-mobile {
    display: none;
}

.hero__btn {
    background: transparent;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
    border: none;
    outline: none;
    opacity: .7;
    cursor: pointer;
    transition: .2s all;
}

.hero__btn:hover {
    opacity: 1;
}

.hero__btn__img {
    width: 30px;
    height: auto;
}

.hero__btn--prev {
    left: 2%;
}

.hero__btn--prev .hero__btn__img {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.hero__btn--next .hero__btn__img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.hero__btn--next {
    right: 2%;
}

.slider {
    position: relative;
}

.afterSlider {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
}

.afterSlider__imgWrapper {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.row,
.infoSection {
    position: relative;
}

.infoSection {
    min-height: 400px;
}

.anchor {
    position: absolute;
    top: -230px;
    left: 0;
}

.row .anchor {
    top: -150px;
}

.container--single select {
    padding: 5px 25px;
}

.page--beforeCheckout {
    margin: 10vh auto;
}

.label--search {
    position: relative;
}

.label--search::after {
    content: "";
    background: url(/static/media/search-icon.39fefb0b.svg);
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-size: 12px
}

.menu__search {
    opacity: 0;
    height: 0;
    transition: .2s all;
    position: absolute;
    top: 200px;
    left: 9.2%;
}

.menu__main {
    transition: .2s all;
}

.searchResult {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E3E3;
    display: block;

}

.search__price {
    margin-left: 30px;
}

.menu__search .cart__item__firstCol {
    max-width: none;
    width: 100%;
}

.mobileBar {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
    left: 0;
    background: #fff;
    z-index: 9;
    overflow: hidden;
}

.searchMobile {
    position: absolute;
    top: 45%;
    left: 10%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0;
    z-index: -2;
    width: 0;
    transition: .2s all;
}

.searchMobile .searchInput {
    border: none;
    border-bottom: 1px solid #E3E3E3;
    height: 40px;
    outline: none !important;
    width: 90%;
}

.menu__search--mobile {
    background: #fff;
    left: 0;
    padding: 5%;
    top: 150px;
    overflow: scroll;
    position: fixed;
}

.menu__search--mobile .cart__item__imgWrapper {
    width: 85px;
}

.search__price {
    white-space: nowrap;
}

.page--terms {
    max-width: 100%;
}

.page__text {
    margin-top: 15px;
}

.myAccount__section {
    position: relative;
}

.myAccount__section .response {
    top: 100%;
    left: 0;
    color: #000;
    margin-top: 15px;
}

.order__bottom {
    margin-top: 10px;
    padding: 20px 0;
    border-bottom: 1px solid #E3E3E3;
}

.order__bottom__right {
    width: 20%;
    max-width: 300px;
}

.btn--order {
    font-size: 12px;
    margin-bottom: 15px;
    height: 35px;
    transition: .2s all;
}

.btn--order:hover {
    background: #250C37;
    background: var(--hover);
}

.order__bottom__left *,
.order__bottom__left__header {
    font-size: 14px;
}

.order__bottom__left span {
    display: block;
}

.ty__header {
    margin: 0;
}

.ty__smallHeader {
    margin-top: 15px;
    font-size: 13px;
    color: #6B6B6B;
    font-weight: 400;
}

.animation {
    margin: 20px auto 40px;
}

.animation__logo {
    opacity: 0;
    transition: .2s all;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.circle{
    height: 200px;
    width: 200px;
}

.circle-outer-border {
    position: relative;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    overflow: hidden;
}

.circle-fill{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2B113E;
}

.startAnimation {
    -webkit-animation: fillerup 3s;
    animation: fillerup 3s;
}

@-webkit-keyframes fillerup {
    0% {
        top: 200px;
    }
    100% {
        top: 0px;
    }
}

@keyframes fillerup {
    0% {
        top: 200px;
    }
    100% {
        top: 0;
    }
}

.ty__content {
    opacity: 0;
    transition: .3s all;
}

.page--ty--noMargin {
    margin-top: 20px;
}

.page--100 {
    max-width: 100%;
}

.container--shop .infoSection__btn {
    display: none !important;
}

.shop__header {
    position: relative;
}

.shop__header__btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    transition: .2s all;
}

.shop__filters .shop__header__btn:hover {
    opacity: 1;
}

.shop__arrow {
    width: 10px;
    margin-left: 5px;
}

.infoSection {
    overflow: hidden;
}

.shop__filters {
    position: absolute;
    right: 0;
    bottom: 30px;
    opacity: 0;
    transition: .2s all;
}

.shop__filters .shop__header__btn {
    margin-left: 20px;
    opacity: .7;
}

.sortBtn {
    margin-top: -50px;
}

.inPostModalWrapper {
    display: block;
    height: auto;
    visibility: visible;
}

.modalHidden {
    visibility: hidden;
    height: 0;
}

.container--registerTy .ty__header {
    margin: auto;
}

.outOfStock__header {
    font-size: 15px;
    font-weight: 600;
    color: #941212;
}

.outOfStock__btn {
    padding: 5px 20px;
}

.outOfStock__formSubmit {
    white-space: nowrap;
    padding: 5px 20px;
    margin-left: 15px;
}

.notificationForm {
    margin-top: 10px;
}

.notificationStatus {
    font-size: 15px;
    margin-top: 10px;
}

.infoSection__imgWrapper video {
    width: 100%;
    height: 100%;
}

.infoSection__imgWrapper iframe {
    width: 100%;
    height: 100%;
}

.banner video {
    height: 100%;
    width: 100%;
}

@media(max-width: 1600px) {
    .category__content {
        grid-template-columns: repeat(3, 30%);
    }

    .addToCartBtn--category {
        padding: 10px;
        width: 100%;
        justify-content: center;
    }

    .newsletter__input {
        width: 350px;
    }

    .newsletter__text {
        margin-left: 0;
    }
}

@media(max-width: 1400px) {
    .recom__item {
        flex-basis: 30%;
    }

    .infoSection--2 .infoSection__content {
        width: calc(100% - 625px);
    }

    .addToCartBtn--category {
        font-size: 13px;
    }

    .infoSection__content {
        width: 60%;
    }

    .infoSection__content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .newsletter__header {
        font-size: 24px;
    }

    .newsletter__text {
        font-size: 14px;
    }
}

@media(max-width: 1300px) {
    .banner {
        max-height: 28vw;
    }

    .newsletter__input {
        width: 200px;
    }
}

@media(max-width: 1200px) {
    .modalHeader {
        font-size: 18px;
    }

    .infoSection__imgWrapper iframe {
        min-height: 350px;
    }

    .deliveryData {
        max-width: 46%;
    }

    .page--beforeCheckout__back {
        white-space: nowrap;
    }

    .deliveryData__header,
    .footer__text--big {
        font-size: 21px;
    }

    .footer__col__logoWrapper {
        width: 60px;
    }

    .infoSection__imgWrapper {
        width: 100% !important;
        height: auto !important;
    }

    .header__header__section__btn,
    .header__menu__list__link {
        font-size: 14px;
    }

    .recom__item__title {
        font-size: 17px;
    }

    .recom__item__price {
        font-size: 21px;
    }

    .addToCartBtn--crossSells {
        font-size: 14px;
        padding: 15px 10px;
    }

    .singleProductInfo__title {
        font-size: 22px;
    }

    .singleProductInfo__price {
        font-size: 18px;
    }

    .category .singleProductInfo__sizes__buttons {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
    }

    .myAccount, .myAccount__buttons {
        width: 100%;
    }

    .cart {
        width: 80%;
    }

    .menu {
        width: 100%;
    }

    .preview__title {
        font-size: 16px;
    }

    .addToCartBtn {
        font-size: 14px;
    }

    .preview__subtitle {
        font-size: 13px;
    }

    .preview__price {
        font-size: 18px;
        margin: 10px 0;
    }

    .row__header {
        font-size: 24px;
    }

    .infoSection {
        flex-direction: column;
        max-width: 700px;
        height: auto !important;
    }

    .infoSection__btn {
        max-width: 700px;
        margin: 10px auto;
    }

    .infoSection > * {
        width: 100% !important;
    }

    .newsletter__header {
        padding-right: 20px;
    }

    .page--beforeCheckout:not(.page--cart) .flex > div {
        width: 48%;
    }

    .page--beforeCheckout .flex > div .page__header {
        font-size: 21px;
        margin-bottom: 15px;
    }

    .container--deliveryData .cart {
        width: 50%;
    }

    .form--deliveryData {
        width: 40vw;
    }

    .singleBottom__section,
    .singleMiddle,
    .single__top {
        flex-direction: column;
        align-items: center !important;
    }

    .singleMiddle > *,
    .single__top > * {
        width: 100%;
        max-width: 600px;
    }

    .singleMiddle__imgWrapper,
    .single__gallery {
        margin-bottom: 30px;
        margin-right: 0;
    }
}

@media(max-width: 996px) {
    .products, .row > main.flex {
        grid-template-columns: repeat(2, 48%);
        grid-row-gap: 30px;
    }

    .menu {
        max-width: 100vw;
    }

    .order__bottom__right {
        width: 60%;
    }

    .deliveryData__header {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .menu__main {
        -webkit-transform: scale(.8);
                transform: scale(.8);
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }

    .topMenu__list__link {
        font-size: 12px;
    }

    .topMenu__icon {
        width: 12px;
    }

    .siteHeader__logoWrapper {
        width: 100px;
    }

    .topMenu {
        margin-top: 110px;
    }

    .siteHeader__right {
        width: 170px;
    }

    .siteHeader__right__item {
        font-size: 12px;
    }

    .siteHeader__right__item {
        width: 80px;
    }

    .siteHeader__menuBtn {
        width: 120px;
    }

    .footer__col__logoWrapper {
        display: none;
    }

    .footer__main {
        flex-wrap: wrap;
    }

    .footer__col {
        width: 49%;
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .footer__col__content {
        margin-left: 0;
    }

    .newsletter {
        flex-wrap: wrap;
        justify-content: center;
    }

    .newsletter__text {
        margin-left: 20px;
    }

    .newsletter__form {
        width: 100%;
        margin-top: 20px;
        justify-content: center;
    }

    .page--beforeCheckout > .flex {
        flex-wrap: wrap;
    }

    .page--beforeCheckout > .flex > span {
        display: none;
    }

    .page--beforeCheckout:not(.page--cart) .flex > div {
        width: 100%;
    }

    .page--beforeCheckout:not(.page--cart) .flex > div:first-of-type {
        padding-right: 0;
    }

    .page--beforeCheckout:not(.page--cart) .flex > div:last-of-type {
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .d-900 {
        display: none;
    }

    .single__gallery__bottom__item {
        height: 80px;
    }

    .single__price,
    .single__title {
        font-size: 21px;
    }

    .single__price {
        margin-left: 30px;
        white-space: nowrap;
    }

    .singleBottom__section .single__desc {
        width: 100%;
        max-width: 800px;
    }

    .page__text p {
        font-size: 13px;
    }

    .myAccount__main {
        justify-content: space-between;
    }

    .page--myAccount {
        max-width: 100%;
    }

    .myAccount__section {
        width: 48%;
    }
}

@media(max-width: 850px) {
    .menu__main {
        -webkit-transform: scale(.7);
                transform: scale(.7);
    }
}

@media(max-width: 768px) {
   .page__header {
       font-size: 24px;
   }

    .afterSlider {
        grid-column-gap: 10px;
    }

    .slider {
        margin: 20px auto 10px;
    }

    .container .siteHeader {
        min-width: 90vw;
    }

    .btn--continue {
        font-size: 14px;
    }

    .btn--payment {
        margin-bottom: 40px;
    }

    .order__bottom > * {
        width: 100%;
        margin-top: 25px;
        text-align: center;
    }

    .circle{
        height: 100px;
        width: 100px;
    }

    .order__bottom {
        justify-content: center;
    }

    .order__bottom,
    .myAccount__main {
        flex-wrap: wrap;
    }

    .myAccount__main > * {
        width: 100%;
    }

    .codeForm label {
        margin-right: 7px;
    }

    .deliveryData__header--shippingAndPayment {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .container--sAndP .deliveryData {
        max-width: 100%;
    }

    .sAndP__main {
        max-width: 100%;
    }

    .merits {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .merits__item {
        width: 47%;
        margin-bottom: 20px;
    }

    label {
        margin: 3px 0;
    }

    .btn--submit,
    .input {
        height: 40px;
    }

    .ty__header {
        font-size: 21px;
    }

    .ty__subheader {
        font-size: 15px;
    }

    .infoSection__btn {
        font-size: 12px;
    }

    .cart {
        width: 100%;
    }

    .cart__header {
        font-size: 21px;
    }

    .cart__item {
        flex-wrap: wrap;
    }

    .cart__item__firstCol {
        width: 100%;
        max-width: none;
        margin-bottom: 15px;
    }

    .d-desktop {
        display: none;
    }

    .d-mobile {
        display: block;
    }

    .siteHeader__hamburgerMenu {
        width: 30px;
        background: transparent;
        border: none;
        outline: none;
    }

    .siteHeader__logoWrapper {
        position: relative;
        top: unset;
        left: unset;
        width: 50px;
        -webkit-transform: none !important;
                transform: none !important;
    }

    .siteHeader {
        align-items: center !important;
    }

    .container .mobileBar {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-top: 100px;
        padding: 15px 0;
        border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
    }

    .mobileBar__btn {
        background: transparent;
        border: none;
        outline: none;
        width: 25px;
    }

    .hero__btn__img {
        width: 15px;
    }

    .afterSlider {
        margin-bottom: 0;
    }

    .afterSlider__imgWrapper {
        height: auto;
    }

    .menu__main {
        flex-wrap: wrap;
        -webkit-transform: none;
                transform: none;
        height: 80vh;
        flex-direction: row;
        overflow-y: scroll;
    }

    .menu__main .menu__main__categoryBlock {
        width: 100%;
    }

    .menu {
        padding-top: 20px;
    }

    .menu__top {
        position: absolute;
        top: 25px;
        right: 20px;
    }

    .menu .page__header {
        margin-top: 0;
    }

    .page--cart .cart__item__firstCol,
    .page--cart .cartCol:nth-of-type(2) {
        width: 50%;
    }

    .form--deliveryData {
        width: 90vw;
        margin-bottom: 20px;
    }

    .form--deliveryData .btn--submit {
        margin-bottom: 0;
    }

    .container--deliveryData .cart {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }

    .form .btn--submit,
    .form {
        margin-bottom: 10px;
    }

    .singleOrder__value, .singleOrder__key,
    .btn--myAccount {
        font-size: 12px;
    }

    .btn--myAccount {
        height: 40px;
    }

    .menu__search--mobile .noProducts {
        font-size: 13px;
    }

    .sortBtn {
        margin-top: -20px;
        white-space: nowrap;
        margin-left: 20px;
    }

    .shop__filters {
        display: flex;
        flex-direction: column;
        bottom: -50px;
        background: #fff;
    }

    .shop__filters .shop__header__btn {
        margin-bottom: 5px;
    }

    .newsletter__form .error {
        text-align: center;
        bottom: -80px;
    }

    .container--deliveryData .deliveryData {
        max-width: 100%;
    }

    .modal--inPost .ReactModal__Content {
        top: 0 !important;
        left: 0 !important;
        width: 100%;
        height: 100%;
        padding: 50px 20px 10px !important;
    }

    .modal--inPost .ReactModal__Content .modalClose {
        top: 10px;
        right: 10px;
        width: 15px;
    }
}

@media(max-width: 576px) {
    .products, .row > main.flex {
        grid-template-columns: repeat(1, 100%);
    }

    .merits__header {
        margin: 10px auto;
    }

    .merits__item {
        width: 100%;
        margin-bottom: 30px;
    }

    .infoSection__content {
        padding: 20px 10px;
    }

    .newsletter__form {
        flex-wrap: wrap;
    }

    .newsletter__input,
    .newsletter__form > * {
        width: 100% !important;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .newsletter__header, .newsletter__text {
        width: 100%;
        border: none;
        margin: 10px 0;
        text-align: center;
        padding: 0;
    }

    .footer__col {
        width: 100%;
    }

    .footer__main {
        padding-bottom: 0;
    }

    .page__header {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .page--beforeCheckout {
        margin-top: 70px;
    }
}

